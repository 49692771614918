/* for std test section of landing page  */
/* Commmon style */
@import url("https://fonts.googleapis.com/css2?family=Jost:wght@300;400;500;600;700;800;900&display=swap");
/* @import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);  */
@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);
@import url(https://s3.amazonaws.com/assets.freshdesk.com/widget/freshwidget.css);

:root {
  --theme_color: #368fff;
  --theme_female_color: #fdd9b5;
}

.async-hide {
  opacity: 0 !important;
}

/* .container-fluid{
   max-width: 1240px;
 } */
body .container {
  max-width: 1170px;
}

.mobile-view {
  display: none !important;
}

button:focus {
  outline: none !important;
  box-shadow: none !important;
  border: none;
}

.float-right {
  float: right;
}

.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) !important;
  margin: inherit !important;
}

body select:focus {
  outline: 0px;
}

.lg-form-control {
  border: 1px solid #999999;
  padding: 8px 12px;
  font-weight: 400;
  color: #1f1d1d;
  border-radius: 3px;
}

.text-light-blue {
  color: #484c51;
}

.btn-check:focus+.btn,
.btn:focus {
  outline: 0 !important;
  box-shadow: none !important;
}

.main-footer {
  background: #bad8ff6b;
}

/* .main-footer {
    background: #F6FFFE;
} */
.section-gap {
  padding: 45px 0;
}

.section-gap-3 {
  padding: 45px 0;
}

.section-gap .main-footer {
  padding: 30px 0;
}

.mo-position {
  position: relative;
  bottom: -26vh;
}

.card-info-input iframe html {
  display: none;
  width: 33.33%;
  border: 1px solid;
}

/* buttons */
body a {
  color: rgb(54, 53, 53);
  text-decoration: none;
  font-weight: 500;
}

.testimonial .happy-customers-title h2:after {
  /*border-bottom: 2px solid #fff;*/
}

.section-title1 hr {
  width: 42px;
  height: 1px;
  background: white;
  opacity: 1;
  color: white;
  margin: auto;
}

.weight-cart hr {
  width: 42px;
  height: 2px !important;
  background: var(--theme_color);
  opacity: 1;
  color: var(--theme_color);
  margin: auto;
}

.partners h2:after,
.question-main h2:after {
  left: 26px !important;
  transform: translate(-50%, -50%);
}

.partners1 h2:after {
  left: 26px !important;
  transform: translate(-50%, -50%);
}

body input:focus {
  outline: 0px;
}

/* buttons */
body .btn {
  padding: 8px 22px;
  border-radius: 5px;
  font-weight: 500;
}

body .btn-rounded-primary {
  border: 1px solid var(--theme_color);
  color: var(--theme_color);
  transition: all 0.5s;
  box-shadow: 0 3px 4px 0 rgb(29 92 185 / 5%);
}

body .btn-rounded-primary:hover {
  border: 1px solid #fff;
  color: #fff;
  background: var(--theme_color);
}

body .btn-lg-success {
  border: 1px solid;
  color: white;
  background: #26af74;
  transition: all 0.5s;
  box-shadow: 0 3px 4px 0 rgb(29 92 185 / 5%);
}

body .btn-lg-secondary {
  border: 1px solid;
  color: white;
  background: var(--theme_color);
  transition: all 0.5s;
  box-shadow: 0 3px 4px 0 rgb(29 92 185 / 5%);
}

body .btn-lg-secondary:hover {
  border: 1px solid;
  color: var(--theme_color) !important;
  background: #fff;
}

body .btn-lg-secondary.loader {
  background: #e2e5eb;
}

body .btn-lg-secondary.loader:hover {
  background: #e2e5eb;
}

/* button end */
.card-body-1 {
  flex: 1 1 auto;
  padding: 20px 15px 0px 15px;
}

.navbar-brand img,
.footer-logo img,
.logo-img {
  width: 148px;
}

.logo-img {
  width: 140px;
  position: relative;
  top: -23px;
}

/* The checkbox-main */
.checkbox-main {
  display: block;
  position: relative;
  padding-left: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
  color: #999999;
}

/* Hide the browser's default checkbox */
.checkbox-main input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

*:focus {
  outline: 0 !important;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 4px;
  left: 0;
  height: 15px;
  width: 15px;
  border: 2px solid #c3c1c1;
  border-radius: 2px;
}

.checkout-card-content.checkout-first-card {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.checkout-card-content.checkout-first-card h5 {
  width: 100%;
}

.checkout-first-card .pricing {
  text-align: right;
  font-weight: bold;
}

/* When the checkbox is checked, add a blue background */
.checkbox-main input:checked~.checkmark {
  background-color: #77acf1;
  border: 2px solid #77acf1;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-main input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-main .checkmark:after {
  left: 3px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Commmon style End*/
.std-1st-div {
  padding-left: 20px;
}

.std-text {
  width: 100%;
}

p.std-text.text-light-blue {
  font-size: 18px;
}

.std-tags-iiner span {
  padding-left: 10px;
  color: #000000;
  font-size: 15px;
  display: inline-block;
}

.heightlighted-std {
  font-weight: 500;
}

body .black-lg-text {
  color: #000;
}

.checkicon-color {
  position: relative;
}

.checkicon-color:before {
  content: "\f00c";
  font-family: FontAwesome;
  font-size: 11px;
  position: relative;
  left: -6px;
  top: -3px;
  color: var(--theme_color);
}

.checkicon-color:after {
  content: "";
  position: absolute;
  border: 2px solid;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  left: 0;
  color: var(--theme_color);
}

.main-wrapper .alert {
  margin-bottom: 0;
  border-radius: 0px;
}

.hideBanner1 {
  display: none;
}

.fda-icon {
  width: 60px !important;
}

.std-main {
  background-color: var(--theme_color) 0f;
  padding-bottom: 100px;
}

.std-1st-div .btn-div {
  margin: 20px 0;
  text-align: center;
}

.container.row-fixed {
  margin-top: -50px;
}

.hero-cards-inner h4 {
  padding-left: 20px;
}

.hero-cards-inner {
  width: 100%;
  background-color: #fff;
  box-shadow: 0px 3px 19px #0000001a;
  border-radius: 10px;
  margin-top: 0px;
  height: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 10px;
  z-index: 99;
  position: relative;
}

a.hero-cards {
  display: block;
  margin-bottom: 20px;
}

.hero-cards-inner img {
  width: 70px;
  margin-right: 10px;
}

.std-1st-div .btn {
  padding: 12px 30px;
  font-size: 18px;
  max-width: 400px;
  width: 100%;
}

.container.std-container {
  padding: 49px 69px;
}

.std-tags {
  margin-top: 15px;
}

.std-tags-iiner {
  padding: 8px 0;
  display: flex;
}

.std-div-dotImg {
  background-image: url(/images/allergy-hero-section.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  height: 100%;
}

.std-div-dotImg1 {
  background-image: url(/images/std.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  height: 100%;
}

.std-div-main {
  background-image: url(/landing/stdmain.webp);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;
  height: 100%;
}

.std-div-dotImg2 {
  background-image: url(/images/covid.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  height: 100%;
}

.std-div-dotImg3 {
  background-image: url(/images/allergy.gif);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position: relative;
  height: 100%;
}

.call-us i,
.login-navbar-btn i {
  padding: 10px 8px;
  font-weight: bolder;
  color: #296ace;
  cursor: pointer;
  position: relative;
  top: 0;
}

/* popular cart section for landing page  */
.cart-view-card {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background: #fff;
  border: none !important;
  border-radius: 10px !important;
}

.cart-section:hover {
  box-shadow: 0 0.125rem 1.5625rem rgb(0 0 0 / 10%);
  transition: all 0.5s;
}


.input-group-diseases {
  display: flex;
  /* background-color: #ecefff; */
  color: #445973;
  min-height: 40px;
  border-radius: 0;
  display: inline;
}

.input-group-diseases img {
  margin-right: 5px;
  height: 25px;
  width: 25px;
  vertical-align: bottom;
}

.input-group-diseases.treatement_icon {
  float: right;
}

i.fa.fa-times {
  color: var(--theme_color);
}

.card-btn-main {
  padding: 0px 0px 9px;
  width: 100%;
}

.card-btn-main .btn {
  width: 100% !important;
  padding: 14px 12px;
  background: var(--theme_color);
}

body .card-btn-main .btn:hover span {
  color: var(--theme_color) !important;
}

.test-shadow:hover {
  box-shadow: 1px 20px 20px 0px rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%);
}

.table {
  text-align: center;
}

.table tfoot tr td {
  border: none;
}

table td,
.table th {
  text-align: left;
}

.compare-text-table tr th {
  width: 25%;
}

.compare-text-table.table> :not(:last-child)> :last-child>* {
  border-bottom-color: #00000012 !important;
}

/* .tb-compare-test.compare-text-table tr td:nth-child(2),
.tb-compare-test tr th:nth-child(2) {
  padding-bottom: 20px;
  margin: 0px;
  background: white;
  box-shadow: 0 10px 10px 0 rgb(34 88 167 / 32%);
  border-left: 1px solid;
    border-right: 1px solid;
} */

/* .compare-text-table tr td:nth-child(2):hover{
    box-shadow: 0 16px 16px 0 rgba(95, 109, 179, .2);
} */
/* .border-popular-top{
    border-top: 1px solid !important;
    position: relative;
    border-top-left-radius: 10px;
    display: block;
    border-top-right-radius: 10px;
}
.border-popular-bottom{
    border-bottom: 1px solid !important;
    border-bottom-left-radius: 10px;
    display: block;
    border-bottom-right-radius: 10px;
} */
/* .border-popular-top:after {
    content: 'MOST POPULAR';
    position: absolute;
    top: -14px;
    width: 150px;
    background: #07039b;
    left: 22%;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    padding: 2px 4px;
} */
.competitors-logo p {
  position: relative;
  top: 0px;
  font-weight: bold;
  color: #77acf1;
}

.add-to-cart-btn .btn {
  margin: 0 2px;
  max-width: 240px;
  width: 100%;
}

.logo-icon {
  width: 36px;
}

/* table.cart-table tr td:nth-child(3),
table thead th:nth-child(3) {
    padding: 0px 44px;
} */
#right,
.table td+td,
.table th+th {
  text-align: center;
}

.cart-table {
  width: 100%;
}

.cart-table .header-table {
  border-bottom: 1px solid #e9eaea;
}

.cart-table tr {
  border-bottom: 1px solid #e9eaea;
}

.cart-table th {
  font-size: 16px;
  font-weight: 500;
}

.compare-text-table tr td:first-child {
  border: none;
}

.table th {
  border: none;
}

.table .btn.cart-btn3 {
  background: #2457b9;
  color: #fff;
}

.btn.cart-btn2 {
  background: #2457b9;
  color: #fff;
  padding: 5% 32%;
}

.heading-div1 {
  text-align: right;
  position: relative;
  top: 3px;
}

.heading-div .cart-heading {
  font-size: 19px;
  color: black;
  font-weight: 600;
}

.heading-div1 .cart-heading {
  font-size: 18px;
  font-weight: 600;
  color: black;
}

.cart-h4 {
  font-size: 18px;
  font-weight: 500;
}

h1.cart-heading3 {
  font-size: 25px;
  font-weight: 500;
}

.question-main {
  margin-bottom: 9%;
}

.card.cart-view-card.card-border {
  border: 2px solid #77acf1;
}

/* .card.cart-view-card {
  height: 602px;
} */
.most-popular {
  border: 2px solid #77acf1;
  border-radius: 10px;
  position: relative;
}

.most-popular.border-color {
  border: 2px solid var(--theme_color);
}

.most-popular.border-color label.card-label-2 {
  border: 2px solid #4c99dc;
  width: 45%;
  background: #4c99dc;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -16px;
  border-radius: 6px;
  left: 30px;
  font-size: 13px;
}

.most-popular-doctor {
  border: 2px solid #3e6aa5;
  border-radius: 10px;
  position: relative;
}

.most-popular-doctor.border-color {
  border: 2px solid #3e6aa5;
}

.most-popular-doctor.border-color label.card-label-2 {
  border: 2px solid #3e6aa5;
  width: 45%;
  background: #3e6aa5;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -16px;
  border-radius: 6px;
  left: 30px;
  font-size: 13px;
}

label.card-label {
  border: 2px solid #4c99dc;
  width: 30%;
  background: #4c99dc;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -16px;
  border-radius: 6px;
  left: 30px;
  font-size: 13px;
}

/* for work section  */
span.work-list-span {
  font-size: 14px;
  font-weight: 100;
  /* font-family: jost; */
}

.work-title {
  font-size: 21px;
}

.card-text.work-text {
  font-weight: 500;
}

.work-card-img {
  /* position: relative; */
  display: inline-block;
  /* left: -44px; */
  width: 100%;
}

.get-result-icon {
  left: 3px;
  width: 40px !important;
  top: 3px;
}

.get-location-icon {
  top: 2px;
}

.work-card img {
  width: 200px;
  z-index: 9;
  position: relative;
  margin: 0 auto;
  display: block;
}

/* .work-card-description {
    padding-top: 20px;
} */

.work-card-img:after {
  content: "";
  position: absolute;
  top: -15px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  left: 0px;
  z-index: 1;
}

.card-text.work-text.work-light-text {
  font-size: 14px;
  font-weight: 100;
}

.main-work .work-card {
  border: none;
  margin-top: 30px;
  display: "flex" !important;
  flex-direction: "row";
  align-items: center;
}

g {
  box-shadow: 0 2px 12px 0 rgb(95 109 179 / 16%);
  border-radius: 10px;
}

.work-spacing {
  margin-top: 2%;
}

.work-spacing .col-md-2.col-1 {
  width: 5%;
}

.work-spacing img {
  width: 18px;
}

.work-txt {
  font-size: 14px;
  font-weight: 100;
}

.work-list {
  font-size: 16px;
  font-weight: 100;
}

/* for healthcare testing */
/* .healthcare-main {
  overflow: hidden;
  height: 700px;
} */
.container.healthcare {
  padding: 4% 4%;
}

.healthcare-slot {
  padding-top: 45px;
}

.healthcare-heading {
  padding-top: 10%;
  max-width: 350px;
}

.healthcare-testing-box {
  display: flex;
}

.healthcare-testing-box .detail {
  padding-left: 20px;
}

.healthcare-testing-box .healthcare-p {
  width: 60%;
  font-size: 14px;
}

.homecare-content ul {
  list-style: none;
  counter-reset: steps;
  padding-left: 0;
}

.homecare-content ul li {
  counter-increment: steps;
  padding: 8px 0px;
}

.homecare-content ul li::before {
  content: counter(steps) "";
  margin-right: 0.5rem;
  background: var(--theme_color);
  display: inline-block;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  color: #ffffff;
  font-weight: 600;
  font-size: 13px;
  padding: 3px 8px;
}

.ordersum-test-img {
  display: inline-block;
  padding-right: 15px;
}

.ordersum-test-img img {
  width: 80px;
}

.healthcare-testing-box .detail h4 {
  font-size: 18px;
}

.healthcare-testing-box .icon img {
  width: 23px;
}

.btn.healthcare-btn {
  background: #2457b9;
  padding: 9px 30px;
  color: white;
  margin-top: 40px;
}

.btn.healthcare-btn:hover {
  background: #042053;
}

.healthcare-mobile-img-section {
  text-align: center;
}

.healthcare-mobile-img-section img {
  width: 100%;
  max-width: 330px;
}

.row.healthcare-slot {
  margin-top: 9%;
}

.row.healthcare-track {
  margin-top: 3%;
}

.row.healthcare-result {
  margin-top: 2%;
}

/* testimonial section */
.testimonial {
  background: #f6fffe;
  padding: 30px 0 30px;
}

.review-count {
  box-shadow: 0 1px 4px 0 rgba(95, 109, 179, 0.24);
  transition: all 0.3s;
  padding: 10px;
  background: white;
  border-radius: 10px;
  display: flex;
  transition: all 0.5s;
}

.review-count:hover {
  transform: translateY(-5px);
}

.exellent-img {
  background: #77acf1;
  display: inline-block;
  padding: 12px 15px;
  border-radius: 12px;
  margin-right: 20px;
}

.exellent-img img {
  width: 94px;
}

.review-count .review-title {
  font-size: 21px;
  display: block;
}

.review-count .count-rev {
  padding-left: 10px;
}

.review-count-content .fa-star {
  position: relative;
  top: -2px;
}

/* .trusted-icon {
  float: right;
  font-weight: 600;
} */
/* .trusted-icon .fa-star {
  background: transparent;
  color: #77acf1;
  font-size: 21px;
} */
.fa-star {
  color: white;
  background: var(--theme_color);
  padding: 3px;
  margin: 0px 2px;
  font-size: 10px;
}

.testimonial-h4 {
  font-weight: inherit;
  font-size: 23px;
  color: #fff;
}

.testimonial-span {
  font-weight: 500;
  padding-top: 15px;
  display: block;
  font-size: 14px;
}

span.customer-intials {
  font-size: 14px;
}

.carousel-button-group {
  position: absolute;
  bottom: 0;
  left: 15px;
}

.testimonial .react-multiple-carousel__arrow.react-multiple-carousel__arrow--left,
.testimonial .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  /*background: #77acf1;
    top: 25%;*/
  display: none;
}

.react-multi-carousel-dot-list {
  display: none !important;
}

.testimonial .react-multiple-carousel__arrow::before {
  font-size: 20px;
  color: #fff;
}

a.btn.btn-white.card-btn {
  padding: 10% 16px;
  border: 1px solid #ded9d9;
  margin-left: 15px;
  margin-top: 92%;
}

/* partners and affiliates  */
/* .card-partner1:hover,.card-partner2:hover,.card-partner3:hover,.card-partner4:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
} */
.card-partner {
  background-size: 90%;
}

.card-partner1 {
  border: 2px solid #e2e2e2;
  background-image: url(/images/partner1.webp);
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
}

.card-partner2 {
  border: 2px solid #e2e2e2;
  background-image: url(/images/images.webp);
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url(/images/images.webp);
}

.card-partner3 {
  border: 2px solid #e2e2e2;
  background-image: url(/images/LabcorpLogo.webp);
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
}

.card-partner4 {
  border: 2px solid #e2e2e2;
  background-image: url(/images/empire_city.webp);
  height: 220px;
  background-position: center;
  background-repeat: no-repeat;
}

/* question section  */
.contact-us-card-wrapper {
  box-shadow: 0 1px 4px 0 rgb(95 109 179 / 24%);
  padding: 18px 22px;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.3s;
  border-left: 2px solid #77acf1;
  border-top-left-radius: 2px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 2px;
  margin-bottom: 30px;
}

.contact-us-card-wrapper:hover {
  box-shadow: 0 3px 16px 0 rgb(95 109 179 / 20%);
}

.container.question {
  padding: 4% 4%;
}

.faq-logo {
  width: 18px;
  margin-right: 10px;
}

.custom-accodion {
  max-width: 830px;
  margin: 0 auto;
}

.contact-us-btn {
  text-decoration: none;
  color: #77acf1;
}

.question-p {
  font-size: 21px;
  width: 90%;
  margin-top: 7%;
  font-weight: 100;
}

.row-title-text {
  font-size: 16px;
  color: #445973;
}

.arrow-image.undefined svg {
  border: none;
  fill: #445973 !important;
}

.questions-list {
  border-bottom: 1px solid #e0e0e0;
}

p.question-pq {
  font-size: 18px;
  /* font-family: 'Jost'; */
}

/* test compare  */
.heighlighted-price {
  display: block;
  padding-top: 8px;
}

.compare-text-table tr th {
  color: var(--theme_color);
  font-weight: 500;
}

.compare-text-table tr td {
  color: #66686b;
  font-weight: 400;
  padding: 15px 0;
  filter: grayscale(1);
}

.compare-text-table tr td img {
  width: 15px;
}

.compare-text-table .btn-lg-secondary {
  padding: 12px 45px;
}

.blank-text {
  border-bottom: 3px solid #77acf1;
  height: 10px;
  width: 13px;
  display: block;
  text-align: center;
  margin: 0 auto;
  filter: blur(0.4px);
}

.col-md-4.division1 {
  border: 2px solid #36a1ff;
  padding: 0%;
}

.row.mt-4.test-list {
  border-bottom: 1px solid #e0dddd;
}

.tick-test-img {
  margin-bottom: 7%;
}

p.test-list-heading {
  font-size: 18px;
  font-weight: lighter;
  margin-bottom: 12%;
  margin-top: 8%;
}

.tic {
  border-bottom: 1px solid #e2e2e2;
  margin-top: 7%;
}

.tic1 {
  margin-top: 7%;
}

.tic-img-div {
  min-height: 40px;
}

.col-md-4.division {
  padding: 0px;
}

/* navbar  */
/* nav.navbar.navbar-expand-sm {
  border-bottom: 1px solid #ece1e1;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 99;
} */
.footer-colored-section {
  padding-top: 10px;
}

.footer-list {
  list-style: none;
  font-size: 15px;
  margin-top: 20px;
  padding-left: 0px;
}

/* .main-footer .quicks-links {
  padding-left: 70px;
} */

.footer-list li {
  color: #445973;
  line-height: 1.8;
  display: flex;
}

.quicks-links li a {
  color: #445973;
}

.fotter-list-img {
  padding-right: 7px;
}

.add_policies {
  padding-top: 30px;
}

.add_policies .policy-text {
  font-size: 14px;
  color: #9e9e9e;
}

.add_policies .policy-text .brand-text {
  color: var(--theme_color);
}

.footer-paragraph {
  margin-top: 20px;
}

.fotter-list-img a i {
  font-size: 16px;
  position: relative;
  top: 1px;
  color: #77acf1;
}

.need-help-btn {
  cursor: pointer;
}

.footer-list li span {
  font-size: 14px;
}

.footer-social-icons a {
  color: #77acf1;
}

.footer-rights {
  color: #564f4f;
}

.copy-right {
  border-top: 1px solid #00000012;
  padding: 22px 0 0 0px;
}

.mobile-div img {
  width: 60%;
}

.mobile-div {
  position: absolute;
  left: -30px;
  top: 40%;
}

body .mobile-screen {
  display: none;
}

/* navbar dropdown  */
.dropbtn {
  font-size: 16px;
  background: none;
  border: none;
  color: #405e81;
  /* font-family: 'Jost'; */
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 90px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 99;
  background: white;
  border-radius: 5px;
}

.dropdown-content a {
  color: #909dad;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}

.dropdown-content a:hover {
  background-color: rgb(255, 249, 249);
}

.dropdown:hover .dropdown-content {
  display: block;
}

/* search */
.inner-addon {
  position: relative;
}

/* style glyph */
.inner-addon .glyphicon {
  position: absolute;
  padding: 10px;
  pointer-events: none;
}

.right-addon .glyphicon {
  right: 0px;
}

.right-addon input {
  padding-right: 30px;
}

input.form-control.my-input {
  border: none;
}

.cart-nav {
  text-align: center;
}

.my-testimonial .react-multi-carousel-list {
  /* max-width: 1050px;
  margin: 0 auto; */
  padding: 22px 15px;
}

/* 
.my-testimonial .react-multiple-carousel__arrow {
  border: 1px solid;
  border-radius: 0;
}
.my-testimonial .react-multiple-carousel__arrow::before {
  color: #000;
} */
div.navbar-block {
  width: 100% !important;
  padding: 10px 25px;
}

div.column-align-end-1 {
  max-width: 17%;
}

div.column-align-end-2 {
  max-width: 15%;
}

div.column-align-end-4 {
  max-width: 10%;
}

div.column-align-end-5 {
  max-width: 15%;
}

div.column-align-end-6 {
  max-width: 6%;
}

div.column-align-end-7 {
  max-width: 5%;
  padding-top: 10px;
}

.btn.cart-btn3 {
  background: #2457b9;
  color: #fff;
  padding: 5% 32%;
  margin-top: 32%;
  margin-left: 3%;
  margin-bottom: 7%;
}

.cart-section {
  margin-top: 40px;
}

.background-overlay:after {
  content: "";
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #333;
  cursor: pointer;
  user-select: none;
  z-index: 2;
  opacity: 0.2;
  transition: all 0.5s;
}

.menu-item .dropdown span {
  padding: 12px 0px;
  margin-left: 50px;
  display: inline-block;
  color: var(--theme_color);
  cursor: pointer;
  user-select: none;
  font-weight: 600;
}

.menu-item .dropdown span:after {
  content: "\f107";
  font-family: FontAwesome;
  position: absolute;
  right: -20px;
  color: var(--theme_color);
  font-size: 20px;
  top: 10px;
  font-weight: 600;
}

.menu-item .dropdown-menu.show {
  padding: 0;
  top: 6px !important;
  box-shadow: -2px 5px 6px 0 rgb(173 182 217 / 29%),
    0 0 25px 0 rgb(173 182 217 / 50%);
  border: none;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.dropdown-menu .dropdown-item {
  padding: 0.85rem 1rem;
  color: var(--theme_color);
  font-weight: 500;
}

body .dropdown-item.active,
body .dropdown-item:active {
  background-color: transparent;
  color: #000;
}

.call-us {
  border: 1px solid #e6e6e6;
  padding: 2px 20px;
  border-radius: 15px;
  -webkit-box-shadow: rgb(0 0 0 / 5%) 0px 2px 16px !important;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 16px;
  cursor: pointer;
  margin: 4px 9px 3px 4px;
}

/* .custom-navbar .navbar-nav li {
    padding-left: 14px;
    padding-top: 4px;
}
.custom-navbar .navbar-nav {
    flex-direction: row;
}
.menu-wrapper .nav-link {
    padding-top: 0px;
}
.action-menu-btn ul li {
    margin-right: 18px;
}
.action-menu-btn .navbar-nav {
    align-items: center;
} */

.custom-accodion .accordion {
  border: none;
}

.faq-icon {
  padding-right: 10px;
  font-size: 18px;
}

.custom-accodion .accordion__heading {
  position: relative;
}

.custom-accodion .accordion__button:before {
  position: absolute;
  right: 0;
  top: 20px;
  transition: transform 0.3s ease-in-out;
  transform: rotate(45deg);
}

.accordion__button[aria-expanded="true"]::before,
.accordion__button[aria-selected="true"]::before {
  transform: rotate(225deg) !important;
}

.accordion__item {
  border: none !important;
}

.CardNumberField-input-wrapper {
  width: 258px;
  margin: 0;
  /* background: aquamarine; */
  border: 1px solid #bdb7b7;
  height: 36px;
  border-radius: 4px;
}

.accordion__button:hover {
  color: var(--theme_color);
  background-color: transparent !important;
  transition: all 0.5s;
}

.custom-accodion .accordion__panel {
  color: #445973;
}

.custom-accodion .accordion__button {
  border-bottom: 1px solid #00000038;
  background: transparent;
  font-weight: 600;
  transition: all 0.5s;
  padding: 13px 0;
}

.custom-accodion .accordion__heading {
  font-weight: 700;
  -webkit-filter: grayscale(100%);
}

.custom-accodion .accordion__heading:hover {
  background: none;
  color: #77acf1;
  font-weight: 700;
  -webkit-filter: grayscale(0);
}

.custom-accodion .accordion__panel {
  padding: 10px 0;
  animation: fadein 0.35s ease-in;
}

/* checkout page  */
.checkout-heading {
  padding: 2px 22px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.checkout-heading span {
  font-size: 20px;
  color: #333;
}

/* .shipping-address,
.customer-info {
    margin-bottom: 20px;
    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
    border-radius: 10px;
} */

.symptoms-title {
  padding: 10px 0;
}

.symptoms-title h6 {
  font-size: 1rem;
}

.form-inner input {
  display: block;
  width: 100%;
  margin: 10px 0;
}

.payment-inner input {
  display: block;
  width: 100%;
  margin: 10px 0;
}

.bg-light-theme {
  background: #f6fffe;
}

.bg-lg-info {
  color: black;
}

.checkout-heading h3 {
  color: #fff;
  padding: 6px 4px;
}

form.searching input[type="text"] {
  padding: 7px;
  float: left;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.searching button {
  padding: 7px 14px;
  background: #77acf1;
  color: white;
  border: 1px solid #77acf1;
  cursor: pointer;
  transition: all 0.5s;
  border-top-right-radius: 10%;
  border-bottom-right-radius: 10%;
}

span.symptom-heading {
  color: #555;
  /* top: 15px; */
  font-size: 16px;
  margin-left: 10px;
}

form.searching button:hover {
  background: #77acf1;
  border: 1px solid #77acf1;
}

.search-form form input {
  max-width: 260px;
  width: 100%;
  border-radius: 0px;
}

.search-form label {
  font-size: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  color: #77acf1;
}

.checkout-card {
  background: #fff;
  /* display: flex; */
  margin: 10px 0;
  padding: 17px;
  box-shadow: 0px 8px 20px 0px rgb(95 109 179 / 20%);
  border-radius: 10px;
}

.home-appoit p {
  font-weight: 400;
  color: var(--theme_color);
}

.checkout-card img {
  margin: 16px;
  width: 70px;
  margin: 16px 15px;
}

.checkout-wrapper {
  padding: 50px 0 0;
}

.testing-btn {
  font-size: 13px;
}

.testing-btn button.btn.btn-lg-secondary.testing {
  border-radius: 0;
  font-size: 12px;
  font-weight: 800;
  padding: 4px 14px;
}

.wellness-btn .btn.btn-lg-secondary.wellness {
  font-size: 11px;
  border-radius: 0;
  font-weight: 800;
  padding: 5px 14px;
}

.checkout-card-content {
  /* padding: 16px 10px 0 0px; */
  font-size: 14px;
}

.checkout-card-content p {
  margin-bottom: 4px;
}

.checkout-card-content h5 {
  font-size: 15px;
  font-weight: 600;
}

.checkout-btn {
  display: block;
  text-align: right;
}

.checkout-btn .btn {
  padding: 13px 50px;
  font-size: 18px;
}

/* payment-wrapper */
.payment-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.payment-wrapper h6 {
  width: 100%;
  display: inline-block;
  max-width: 450px;
}

.payment-wrapper div img {
  width: 30px;
  /* height: 40px; */
}

/* .payment-inner3 img {
    height: 64px;
    width: 74px;
}
.payment-inner4 img {
    height: 64px;
    width: 64px;
}
.payment-inner5 img {
    height: 50px;
    width: 80px;
    margin-left: 5px;
} */
.payment-wrapper .payment-inner {
  margin: 0 10px 0 0;
}

#view-tests {
  margin-top: -116px;
  padding-top: 116px;
}

.payment-wrapper .payment-inner a img {
  width: 65px;
}

.fixed-order-summary {
  /* position: sticky; */
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  z-index: 9;
  background: white;
  top: 136px;
}

.accept-text span {
  font-size: 14px;
  padding-left: 25px;
}


/* payment-wrapper end */
.place-order-btn .btn.btn-lg-secondary {
  padding: 11px;
  font-weight: 500;
  width: 100%;
}

/* order-summary */
.order-summary-text {
  /* background: #77acf1; */
  padding: 10px 16px;
  border-bottom: 1px solid #e9eaea;
  /* text-align: center; */
}

.cart-table-card {
  border: 1px solid #ece3e3;
  border-radius: 4px;
  /* border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;
    box-shadow: 0 16px 16px 0 rgb(95 109 179 / 20%); */
}

.customer-verified {
  color: green;
}

.order-summary-text h5 {
  color: black;
  font-size: 20px;
  font-weight: 400;
  margin-top: 6px;
}

.cart-table tr td,
.cart-table th {
  padding: 13px 15px;
}

.cart-table tr td:nth-of-type(2),
.cart-table th:nth-of-type(2) {
  text-align: end;
  max-width: 120px;
}

.health-insurance-toggle span {
  color: #797474;
  font-weight: 400;
}

.order-summary .checkout-card {
  border-left: 0px;
  border-right: 0px;
}

.promo-wrapper,
.gift-wrapper {
  margin-top: 0;
  margin-bottom: 0;
}

.promo-apply form input {
  width: calc(100% - 72px);
}

.promo-text span {
  font-weight: 500;
}

.gift-text {
  color: #399ffd;
  font-weight: 500;
}

.checkbox-main a {
  text-decoration: none;
}

.free-shipping-box {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  border-bottom: 1px solid #e0e0e0;
  margin-bottom: 10px;
  /* display: none; */
}

.free-shipping-content p {
  margin-bottom: 0;
  color: #77acf1;
}

.free-shipping-content {
  padding-left: 15px;
}

.free-shipping-img img {
  height: 40px;
  width: 40px;
}

.my-testimonial {
  margin-top: 3%;
}

.three-dots {
  color: #2254b1;
}

.view-tests-btn .btn {
  max-width: 300px;
  width: 100%;
  padding: 11px;
  margin: 30px 0 0 0;
}

.cart-view-card .heading-div {
  height: 37px;
}

.card-first-row {
  width: 100%;
  max-width: 500px;
}

.modal_instructions {
  position: absolute;
  top: 50%;
  left: 50%;
  padding: 1rem 1rem;
  display: flex;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border-radius: 0.25rem;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  border: none !important;
  min-height: 200px;
  display: flex;
  transform: translate(-50%, -50%);
  z-index: 2;
  flex-direction: column;
}

.modal_instructions_parent {
  margin-bottom: 20px;
}

.helper_span {
  font-weight: 700;
}

.overlayyy {
  height: 100%;
  width: 100%;
  background-color: rgba(1, 1, 1, 0.5);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  justify-content: right;
  opacity: 1;
}

.subs_cards p {
  font-size: 17px;
}

.update_button {
  float: right;
}

.update_button button {
  border: 1px solid;
  color: white;
  padding: 6px;
  background: var(--theme_color);
  box-shadow: 0 3px 4px 0 rgb(29 92 185 / 5%);
}

.card.subscription {
  background: #368fff14;
  border-radius: 20px;
  color: #6c757d !important;
  position: relative;
}

.sub_can_wrapper {
  display: flex;
  justify-content: space-between;
  min-height: 90px;
  align-items: flex-end;
  flex-wrap: wrap;
}

.sub_heading {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
}

.sub_can i {
  padding-right: 12px;
}

.plan_option {
  border-bottom: 1px solid #ffffff;
}

.update_button {
  position: absolute;
  bottom: -81px;
  left: 50%;
  transform: translate(-50%, 10px);
}

.update_button button {
  border-radius: 10px;
}

.Quiz_module {
  padding: 7% 0;
  background-color: #63a4ff;
  background-image: -webkit-linear-gradient(135deg, #63a4ff 0%, #83eaf1 101%);
}

.first_h2 {
  color: #fff;
  margin-bottom: 3rem;
  font-size: 2.4rem;
  line-height: 60px;
}

.first_opp_h2 {
  color: #ffffff;
  margin-bottom: 22px;
  font-size: 2.4rem;
  line-height: 45px;
}

.steps-section__content {
  margin-top: 8%;
}

.second_h2 {
  font-size: 22px;
  line-height: 30px;
  color: #fff;
}

.first_price .second_h2 {
  font-size: 20px;
  line-height: 30px;
  color: #fff;
  font-weight: 900;
}

.steps-section__title {
  color: #ffffff;
}

.step__number {
  margin-bottom: 19px;
  font-size: 27px;
  line-height: 31px;
  display: flex;
}

.step__number i {
  margin-left: 16px;
}

.step__text {
  font-size: 17px;
  line-height: 24px;
}

button.step__btn a,
.am_i_eligible_join a {
  color: #ffffff;
}

button.step__btn a:hover,
.am_i_eligible_join a:hover {
  color: #ffffff;
}

.swiper-slide {
  min-height: 276px;
  padding: 35px 36px 15px 35px;
  color: #484c51;
  background-color: #ffffff;
}

.swiper-slide button {
  width: 100%;
  padding: 18px 18px 17px;
  margin-top: 30px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 8px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: var(--theme_color);
  transition: all 0.2s linear;
  border: none;
}

.card .card-img-top {
  height: 250px;
}

.card_weight {
  height: 83%;
  margin: 2rem;
  padding: 20px;
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  background: #fff;
  border-radius: 8px;
}

.loss_head {
  font-size: 20px;
  margin-top: 20px;
}

.loss_name {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
  color: var(--theme_color);
}

.am_i_eligible {
  text-transform: uppercase;
}

.pricing_programs {
  display: flex;
  justify-content: center;
}

.first_price h3 {
  font-weight: 900;
  line-height: 24px;
}

.first_price p {
  color: #fff;
  font-size: 15px;
  text-align: center;
}

.third_price span {
  font-size: 23px;
  color: #fff;
}

.price_third p {
  color: #fff;
  font-size: 12px;
  position: relative;
  left: 5px;
  top: -10px;
}

.plus_price {
  font-weight: 500;
  font-size: 35px;
  margin-left: 2%;
  color: #fff;
  position: relative;
  top: 34px;
}

.plus_price_normal {
  font-weight: 500;
  font-size: 35px;
  color: #fff;
}

.equal_price {
  font-weight: 500;
  font-size: 35px;
  padding: 0 2% 0 0%;
  color: #fff;
  position: relative;
  top: 34px;
}

.totalprice_h2 .second_h2 {
  font-weight: 800;
  font-size: 27px;
  color: #fff;
  margin-top: 50%;
  width: 89px;
}

p.af-name {
  font-size: 14px;
}

p.af-head {
  font-size: 13px;
  text-align: justify;
}

.third_price {
  display: flex;
}

.third_price h3 {
  font-weight: 600;
  font-size: 40px;
}

.navigate_ins p {
  font-weight: 400;
  color: #ffffff;
}

.navigate_ins {
  max-width: 764px;
  margin: auto;
}

.navigate_ins a:nth-child(1) {
  margin-left: 10px;
}

.navigate_ins a {
  text-decoration: underline !important;
}

p.insurance_para {
  font-size: 35px;
  color: #ffffff;
  text-transform: uppercase;
  line-height: 35px;
  font-weight: 400;
  letter-spacing: -0.015rem;
}

.first_price {
  max-width: 200px;
}

/* end checkout page */
@media (min-width: 768px) {
  span.information-heading {
    font-size: 20px !important;
  }

  .mob-view {
    display: none;
  }

  .question-main {
    margin-bottom: 0;
  }
  .bmi_weight::-webkit-outer-spin-button,
  .bmi_weight::-webkit-inner-spin-button {
    -webkit-appearance:auto;
    margin: 0;
  }
}

@media (max-width: 768px) {
.bmi_weight::-webkit-outer-spin-button,
.bmi_weight::-webkit-inner-spin-button {
  -webkit-appearance:auto;
  margin: 0;
}
  .howback {
    width: 100% !important;
}
.main-box-div.howmuch {
  height: 150px !important;
}
.ant-slider.ant-slider-horizontal {
  margin-top: -25px;
}
  .main-box-div,.howmuch {
    width: 100% !important;
}
  body .btn-subcriiption-wrapper {
    display: grid !important;
    gap: 8px;
    justify-content: normal;
  }
  .main-box-div.bmi {
    width: 100%;
}
.semis.text {
  position: relative;
  bottom: 27px;
  font-size: 13px;
}
.semi.text {
  position: relative;
  bottom: 27px;
  font-size: 13px;
}
.main-box-div.bmi input {
  font-size: 23px;
  width: 100%;
  border-bottom: 1px solid #67acfe !important;
  border: 0px;
}
  .toggle-div {
    padding-left: 0;
    padding-right: 0;
  }

  .card_weight {
    margin: 0;
    height: 95%;
  }

  .steps-section__title .first_h2 {
    color: #ffffff;
    margin-bottom: 22px;
    font-size: 1.6rem;
    line-height: 31px;
  }

  .first_opp_h2 {
    color: #ffffff;
    margin-bottom: 22px;
    font-size: 1.6rem;
    line-height: 31px;
  }

  body img.upfront-img {
    width: 64px !important;
    top: -9px !important;
  }

  span.power-text {
    font-size: 15px !important;
  }

  .card.weightloss-membership-wrapper h5 span,
  .card.weightloss-membership-wrapper h5 {
    font-size: 17px !important;
  }

  span.save-color {
    font-size: 12px !important;
  }

  .absolute_col span {
    font-size: 11px !important;

    line-height: 14px !important;
  }

  button.btn.btn-lg-primary.weightloss-member-btn {
    padding: 7px 67px !important;
    font-size: 16px !important;
  }

  .first_price h3 {
    font-weight: 700;
    font-size: 16px;
    line-height: 16px;
  }

  body .first_price1 {
    text-align: center !important;
    width: 100% !important;
  }

  .first_price p {
    font-size: 10px;
  }

  .equal_price {
    font-size: 25px;
    position: relative;
    top: 37px;
    left: 13px;
    font-weight: 800;
  }

  .third_price span {
    font-size: 16px;
  }

  .price_third p {
    font-size: 10px;
  }

  p.insurance_para {
    font-size: 23px;
    font-weight: 400;
  }

  .navigate_ins p {
    font-size: 14px;
  }

  .third_price h3 {
    font-weight: 600;
    font-size: 26px;
  }

  .plus_price {
    font-size: 22px;
    margin: 0 3%;
  }

  .steps-section__title h3 {
    font-size: 22px;
    line-height: 12px;
  }

  .get_started h1 {
    font-size: 30px !important;
  }

  .steps-section__title p {
    font-size: 15px;
    line-height: 22px;
  }

  .swiper-slide {
    min-height: 226px;
    margin-bottom: 20px;
    padding: 26px 36px 15px 35px;
  }

  .step__text {
    font-size: 15px;
    line-height: 21px;
  }

  .swiper-slide button {
    padding: 15px 8px 15px;
    font-size: 15px;
    line-height: 18px;
  }

  .step__number {
    font-size: 23px;
  }

  .insure-btn1.mb-3 {
    display: flex;
  }

  .update_button {
    position: inherit !important;
  }

  body .insure-btn1 .insurance-t-btn-selected {
    font-size: 12px !important;
    padding: 4px 17px !important;
  }

  body .modal-content {
    max-height: 80vh;
    overflow: auto;
  }

  body .std-product {
    min-height: auto;
  }

  body .quantity-wrapper .input-content {
    padding-left: 10px;
  }

  body .product_detail_main {
    margin: 100px 0 10px;
    padding-left: 0;
  }

  body .carosuel-product {
    bottom: -60px;
    flex-direction: revert;
    justify-content: center;
    width: 100%;
    left: 0;
  }

  body .saparate-section {
    margin-top: 35% !important;
  }

  body .carosuel-product img {
    margin-left: 20px;
  }

  body button.btn.btn-lg-primary.small-btn {
    font-size: 11px;
    font-weight: 700;
    border-radius: 70px;
    padding: 6px 15px;
    margin-top: 12px;
    color: rgb(140, 143, 146) !important;
    background: #fff;
    border: 1px solid #e6e6e6;
  }

  body .insurance-t-btn {
    font-size: 13px !important;
    font-weight: 700;
    border-radius: 70px;
    padding: 6px 15px;
    margin-top: 12px;
  }

  body .insurance-t-btn-selected {
    font-size: 13px !important;
    font-weight: 700;
    border-radius: 70px;
    padding: 6px 15px;
    margin-top: 12px;
  }

  body button.btn.btn-lg-primary.small-btn-selected {
    font-size: 11px;
    font-weight: 700;
    border-radius: 70px;
    padding: 6px 15px;
    margin-top: 12px;
    color: #3890ff;
    border: 1px solid #3890ff;

    background: #ffffff;
  }

  body .policy-section {
    position: relative;
    top: -121px;
  }

  body .add_policies .policy-text {
    font-size: 14px;
    color: #9e9e9e;
    position: relative;
  }

  body .card-checkout {
    position: relative;
    top: -21px;
  }

  body .border-div-pas {
    border: 1px solid #e6e6e6;
    padding: 0;
    position: relative;
    top: -138px;
  }

  body .ss {
    margin-top: 20px;
  }

  body .set-payment-position {
    position: absolute !important;
    width: inherit !important;
    font-size: 12px;
    text-align: center;
    top: -76px !important;
    font-weight: 100;
  }

  body .heading-part-product h3 {
    margin-bottom: -10px;
  }

  body .quantity-wrapper .input-content {
    font-size: 13px;
  }

  body .more-text {
    margin-top: -1px !important;
  }

  body .shipping-address1 {
    margin-top: 0px !important;
  }

  span.form-label.insurance {
    position: relative;
    top: 46px;
  }

  .doc-team-main img {
    width: 71px !important;
    height: 71px !important;
  }

  body .doc-team-main .marc-img {
    margin-top: -8px !important;
  }

  body .doc-team-dec {
    padding: 4px 14px;
  }

  body .doc-team-dec p {
    font-size: 10px;
  }

  body .doc-team-dec h5 {
    font-size: 15px;
  }

  .card-first-row {
    max-width: 250px;
  }

  body .card-title.cross-title {
    font-size: 15px !important;
    width: 70% !important;
  }

  .choose-loc-radio .radio-container {
    margin-bottom: 12px;
    font-size: 15px;
    user-select: none;
  }

  label.radio-container span {
    font-size: 11px !important;
  }

  .panel-banner span {
    font-size: 13px !important;
  }

  .cart-item-inner .cart-content {
    max-width: 195px;
  }

  body .action-btn {
    padding: 8px 15px 7px;
  }

  body .action-plus-btn {
    padding: 8px 15px 7px;
  }

  body .change-email {
    position: absolute !important;
    right: 136px !important;
    font-weight: 500 !important;
    bottom: -30px !important;
    border-bottom: 2px solid var(--theme_color);
  }

  .affirm-payment img {
    width: 26% !important;
  }

  body .fix-btn-subscritpions {
    margin: 0;
    position: fixed;
    padding: 16px;
    background: white;
    bottom: 59px;
    width: 99%;
    left: 0;
    right: 0;
  }

  body .fix-btn-subscritpions-wrapper {
    margin-bottom: 60px;
  }

  .account-info-card .case-title {
    position: relative;
    right: 0px;
    margin-bottom: 20px;
    display: inline-block;
  }

  .login-form {
    padding: 40px 20px !important;
  }


  .action-btn-stripe .StripeElement {
    position: absolute !important;
    top: -53px !important;
  }

  .place-order-btn .btn.btn-lg-secondary img {
    display: inline-block !important;
    width: 48px !important;
    position: relative !important;
    bottom: 6px !important;
    left: 4px !important;
  }

  .save-30 {
    font-weight: 600;
    font-size: 9px;
  }

  .upsells-title {
    font-size: 11px;
    font-weight: 600;
  }

  .work-card-schedual {
    position: relative;
    width: 100% !important;
    padding-left: 0 !important;
  }

  .modal-dialog.custom-modal.success-model.insurance-modal {
    height: 300px !important;
    font-size: 15px;
    font-weight: 100;
  }

  .megamobile-menu {
    display: block !important;
    padding: 0 !important;
  }

  body .main-card-container {
    padding: 10% 0 !important;
  }

  .affirm-testprice1 {
    min-height: 54px !important;
  }

  img.hours {
    position: relative;
    top: -3px !important;
  }

  .section-gap .wave-container-bottom img {
    bottom: -45px;
    height: 45px;
  }

  .section-gap .wave-container-top img {
    top: -20px;
    height: 20px;
  }

  body .membership-banner-content {
    grid-gap: 40px;
    gap: 15px;
    justify-content: space-between;
    padding: 15px 15px;
  }

  body .or-text {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #a09e9e;
    line-height: 0.1em;
    margin: -8px 0 16px;
    position: absolute;
    left: 0;
    top: 54px;
  }

  .days-content.arrow-content {
    width: 32px !important;
  }

  .days-content {
    margin: 0 !important;
  }

  h2.cart-heading.sleep-heading {
    font-size: 17px !important;
  }

  .days-content-wrapper .days-content h4 {
    font-size: 12px;
  }

  .time-content-wrapper .time-content h4 {
    padding-left: 3px;
    font-size: 9px;
    color: var(--theme_color) !important;
  }

  .time-content-wrapper .time-content {
    padding: 12px 7px;
  }

  span.count {
    display: inline-block !important;
  }

  .cart-sidebar {
    background: #fff !important;
  }

  .background-overlay-sidebar:after {
    content: "";
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #333;
    z-index: 2;
    opacity: 0.7;
    transition: all 0.5s;
    display: none;
  }

  .overlay-add.background-overlay-sidebar:after {
    display: block;
  }

  body .hero-circle-shape:before {
    left: 10px;
    width: 70px;
    height: 70px;
    bottom: -20px;
  }

  i.fa.fa-arrow-right,
  i.fa.fa-arrow-left {
    font-size: 14px !important;
  }

  .account-info-card .form-inner {
    margin-bottom: 40px;
  }


  body .main-sidebar-content {
    margin-left: 0px;
    transition: 0.5s all;
    overflow: auto;
    margin-top: 0 !important;
    width: 100%;
    padding: 0px 15px;
    /* position: relative;
        z-index: 9; */
  }

  body .main-sidecontent {
    margin-left: 62px;
    margin-top: 0 !important;
    width: 100%;
    overflow: auto;
    /* position: relative;
        z-index: 9; */
  }

  i.fa.fa-question-circle {
    cursor: pointer;
    position: relative;
    top: 1px;
  }

  body .main-sidecontent table .btn,
  body .main-sidebar-content table .btn {
    width: 133px;
  }

  .main-sidecontent .table-visits td,
  .main-sidebar-content .table-visits td {
    font-size: 14px;
    padding: 10px 15px;
    text-align: center !important;
  }

  .main-sidecontent .test-result,
  .main-sidebar-content .test-result {
    display: block;
  }

  .main-sidecontent .card-result-test .main-sidebar-content .card-result-test {
    margin-bottom: 1rem;
    font-size: 16px;
  }

  table.table-visits th {
    text-align: center !important;
  }

  .sidenav---navtext---1AE_f {
    position: relative;
    top: -4px;
  }

  .test-result .btn {
    margin-left: 10px;
    font-size: 14px;
  }

  .time-content {
    padding: 15px 18px;
    max-width: calc(50% - 10px);
  }

  .time-content .time-circle i {
    font-size: 14px;
  }

  .work-card-inner {
    text-align: center;
  }

  .work-card-logo {
    display: block;
    margin: 0 auto 20px;
  }

  .test-result .btn {
    margin: 7px 0px;
    padding: 8px 10px;
  }

  .health-insurance-toggle span {
    color: #141313;
    font-size: 13px;
    font-weight: 400;
    position: relative;
    top: -3px;
  }

  .search-form form.searching {
    padding: 0;
  }

  .order-summary-moblie.mobile-view {
    padding: 0 28px;
  }

  p.card-title.card-result-title {
    font-size: 17px;
  }

  .mobile-view {
    display: block !important;
  }

  body .container {
    padding: 0 15px;
  }

  span.form-label-2.insurance i {
    top: -4px;
  }

  .call-us i {
    border: 3px solid;
    border-radius: 50%;
    position: relative;
    /* top: 15px; */
    padding: 3px 4px;
    right: 0px;
  }

  .login-navbar i {
    border: 3px solid;
    border-radius: 50%;
    padding: 3px 5px;
    right: 0px;
    cursor: pointer;
    color: #296ace;
    position: relative;
    top: 0;
  }

  .selected-checkbox {
    padding: 5px 15px;
    font-size: 13px;
  }

  .toggle-color {
    margin-top: 5px !important;
  }

  .stepper span {
    font-size: 14px;
    font-weight: 400 !important;
    position: relative;
    left: 0 !important;
  }

  .call-us {
    top: 5px;
    border: none;
    cursor: pointer;
    margin-right: 8px !important;
    margin-left: 0 !important;
    padding: 0;
  }

  body .plc-btn-new1 {
    margin-top: 8px !important;
  }

  body .section-gap1 {
    padding: 0 !important;
  }

  .menu-item .dropdown span {
    margin-left: 0px;
    margin-right: 0px;
    padding: 4px 0px;
  }

  .menu-item .dropdown span:after {
    top: 1px;
  }

  span.fade-test-nav {
    font-size: 14px;
  }

  body .plc-btn-new2 {
    margin-top: -25px !important;
  }

  .ordersum-test-img img {
    width: 56px;
  }

  span.prescription-text-main {
    position: absolute !important;
    top: -6px !important;
  }

  .menu-item-small-screen {
    text-align: end;
    position: relative;
    top: -2px;
    left: 6px;
  }

  /* .menu-item .dropdown span:after {
        right: 8px;
    } */
  .react-tel-input {
    margin-left: 5px !important;
  }

  label.card-label {
    width: 45%;
  }

  label.card-label-2 {
    width: 45%;
  }

  body .section-gap-acne-work {
    padding: 108px 0 !important;
  }

  h2.work-heading {
    margin-top: 10%;
  }

  span.text-danger.error-text.num-err {
    position: relative;
    left: 57px;
  }


  .cart-view-card .input-group-diseases {
    font-size: 13px;
  }

  .cart-view-card .input-group-diseases .disease-icon img {
    padding-right: 5px;
    width: 22px;
    top: 0px;
  }

  .cart-view-card .card-btn-main .btn {
    width: 100%;
    font-size: 14px;
  }

  .react-toggle {
    margin-top: 9px;
  }


  p.insurance-text {
    font-size: 10px;
    margin: 0;
  }

  .menu-item-small-screen {
    text-align: end !important;
  }

  .symptoms-title h6 {
    font-size: 16px;
  }

  element.style {
    z-index: 100;
  }

  .heading-div .cart-heading {
    font-size: 16px;
  }

  .heading-div1 .cart-heading {
    font-size: 16px;
  }

  .top-row {
    margin: 0px 1px !important;
  }

  .affirm-as-low-as1 img {
    width: 27% !important;
    display: block !important;
    margin: 0 auto !important;
  }

  .affirm-testprice p {
    font-size: 16px !important;
  }

  .std-1st-div {
    padding-left: 0;
  }

  .cart-items .f-price {
    font-size: 10px !important;
    letter-spacing: 0.03rem;
  }

  /* body .menu-wrapper .container .btn{
    padding: 8px 12px;
  } */

  .accept-text span {
    padding-left: 0px;
  }

  .navbar-brand img {
    width: 120px;
  }

  .term-p-tag {
    font-size: 13px !important;
  }

  .btn-login,
  .btn-appointment {
    padding: 6px 10px !important;
    font-size: 14px;
  }

  .mega-dropdown {
    float: left;
    overflow: hidden;
    /* width: 100%; */
  }

  .row.top-row {
    margin: 0 3px !important;
  }

  .navbar-brand.checkout-logo img {
    max-width: 150px;
  }

  .intake-stepper span {
    font-size: 12px !important;
    font-weight: 600 !important;
  }

  p.question-p {
    font-size: 16px;
    width: 90%;
    margin-top: 7%;
  }

  .action-menu-btn .navbar-nav {
    align-items: center;
    margin-right: 0 !important;
    width: 100%;
  }

  .compare-text-table {
    margin-bottom: 0;
  }

  .action-menu-btn ul li {
    margin-right: 0;
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
  }

  .my-testimonial {
    margin-top: 0;
  }

  .logo-icon {
    width: 20px;
  }

  body .cost p {
    width: 90% !important;
    font-size: 12px !important;
  }

  body .cost h2 {
    width: 100% !important;
    font-size: 18px !important;
  }

  .logo-img {
    width: 80px;
    top: -20px;
  }

  .cart-section {
    padding: 0;
    margin-bottom: 30px;
  }

  .slider_inner {
    width: 100% !important;
  }

  .react-multiple-carousel__arrow {
    min-width: 30px !important;
    min-height: 30px !important;
  }

  #testimonial-section-partner .react-multi-carousel-item {
    padding: 0px !important;
  }

  .home-logos-images img {
    width: 100% !important;
    max-width: 62px !important;
  }

  .home-logos-images {
    gap: 15px;
    padding: 13px 0px;
  }

  body .home-as-seen {
    font-size: 18px !important;
    margin-left: 3% !important;
    font-weight: 600 !important;
    position: relative;
    top: 9px;
  }

  .work-card-img {
    left: 0px;
  }

  .main-work .work-card {
    padding: 0;
    text-align: center;
    padding: 0 22px 20px;
  }

  .heighlighted-price {
    padding-top: 6px;
    display: block;
  }

  body .panel-banner.weight-loss-banner span {
    font-size: 15px !important;
  }

  .add-to-cart-btn .btn {
    margin: 0 2px;
    max-width: 238px;
    width: 85%;
    padding: 6px;
    font-size: 10px;
  }

  span.form-label.insurance1 {
    position: relative;
    top: 13px;
    left: -16px;
  }

  .fq-hover-div {
    display: flex;
  }

  .order-sumry-mobile.order-summary-detail.active {
    border: 1px solid #d2d2d21c;
    background: #f1fbfd;
  }

  .faq-logo {
    width: 18px;
    margin-right: 10px;
    height: 18px;
    margin-top: 5px;
  }

  .cart-nav img {
    width: 31px;
  }

  .lock-icon {
    margin: 0px;
    margin-bottom: 6px;
  }

  h4.footer-heading {
    margin: 0px;
  }

  body .upsells-information {
    margin-left: 0 !important;
  }

  ul.footer-list {
    padding: 0px;
  }

  .tic1 {
    display: none;
  }

  div.column-align-end-1 img {
    height: 30px;
    width: 100px;
  }

  div.column-align-end-2,
  div.column-align-end-3,
  div.column-align-end-4,
  div.column-align-end-5,
  div.column-align-end-6,
  div.column-align-end-7 {
    display: none;
  }

  .main-wrapper {
    overflow: hidden;
  }

  body .plus_price {
    font-weight: 500;
    font-size: 35px;
    margin-left: 2%;
    color: #fff;
    position: relative;
    top: 33px;
  }

  @media screen and (max-width: 768px) {
    .get_started {
      padding: 17px 0.5rem !important;
    }
  }

  .healthcare-mobile-img-section {
    position: relative;
    padding-bottom: 20px;
  }

  .container.partners {
    padding: 0 12px;
  }

  .healthcare-testing-box .healthcare-p {
    width: 100%;
  }

  /* .healthcare-mobile-img-section:after {
        content: '';
        background: #f6fffe;
        width: 100%;
        position: absolute;
        bottom: 0px;
        height: 40px;
        left: 0;
        right: 0;
    } */
  .navbar .container.navbar {
    padding: 0px;
  }

  

  .f-body {
    padding: 27px !important;
  }

  .healthcare-main {
    overflow: visible;
    height: auto;
    padding-bottom: 20px !important;
  }

  .mobile-div {
    position: absolute;
    left: -20px;
    top: 60px;
  }

  /* span.information-heading {
    font-size: 20px;
    position: relative;
    float: left !important;
    justify-content: left;
    left: 0;
  } */

  /* .main-wrapper {
  padding: 0 15px;
} */
  body .dr-img {
    height: 65px;
    width: 82px;
  }

  body .case-img img {
    border-radius: 50%;
    height: 100%;
    width: 100%;
  }

  .btn.healthcare-btn {
    margin-top: 10px;
  }

  h1.cart-heading.price {
    text-align: right;
  }

  h4.cart-heading.price {
    float: right;
    position: relative;
    top: -5px;
  }

  .healthcare-testing-box {
    margin-bottom: 30px;
  }

  body .plc-btn-new {
    margin-top: 5px !important;
  }

  img.healthcare-mobile-img {
    width: 50%;
  }

  a.btn.btn-white.card-btn {
    border: 1px solid #ffffff;
    margin: 0px;
    padding: 0px;
  }

  .container.std-container {
    padding: 13px 15px;
  }

  body .card-btn-main {
    padding: 0;
  }

  .card-partner {
    margin-bottom: 20px;
    background-size: 100%;
    height: 130px;
    background-size: 90%;
  }

  .card.weightloss-membership-wrapper {
    margin: 14px 0 !important;
  }

  .compare-text-table tr th,
  .compare-text-table tr td {
    font-size: 13px;
  }

  /* button.btn.btn-lg-primary.chooseLocation-tbtn {
        float: right;
        left: 0 !important;
        position: relative;
        width: 100%;
    } */
  .dispay-text.mt-3 {
    width: 87%;
    text-align: left;
  }

  .main-footer .quicks-links {
    padding-left: 15px;
  }

  .img-logo-div.img-logo-4 {
    width: 77px !important;
  }

  .payment-wrapper {
    flex-wrap: wrap;
  }

  .subscription-radio-wrapper {
    font-size: 14px !important;
  }

  .shipping-address .inner-padding {
    padding: 0 15px;
  }

  .shipping-address1 .inner-padding {
    padding: 0 15px;
  }

  .values p {
    font-size: 11px;
  }

  /* .payment-wrapper div {
        width: 33.33%;
    } */
  .order-summary {
    margin-top: 0;
    display: none;
  }

  .std-1st-div .btn {
    max-width: 418px;
    font-size: 18px;
  }

  .hide-button {
    display: none;
  }

  .menu-item-tests {
    text-align: center;
  }

  .news-letter {
    margin-top: 0 !important;
  }

  .std-tags-iiner .fa {
    padding-top: 3px;
    font-size: 18px;
  }

  .desk-view {
    display: none !important;
  }

  .promo-wrapper {
    margin-top: 0 !important;
  }

  .type_msg {
    margin-bottom: 55px;
  }

  .custom-accodion {
    margin-top: 15px;
  }

  .std-tags .std-tags-iiner span {
    padding-left: 6px;
    font-weight: inherit;
  }

  body .partner-section-list {
    display: block;
  }

  .checkicon-color:before {
    font-size: 10px;
    left: -3px;
    top: -1px;
  }

  p.mb-0.login-ask {
    float: left;
    position: relative;
    top: 4px;
    left: 0 !important;
  }

  span.RFS-Label.RFS-Label-d11 {
    font-size: 11px i !important;
  }

  .checkicon-color:after {
    width: 17px;
    height: 17px;
    top: 4px;
  }

  .checkout-wrapper1 {
    display: none;
  }

  span.RFS-Label {
    font-size: 10px !important;
  }

  .login-ask {
    float: left !important;
  }

  body .cross-sells-body button {
    position: absolute;
    right: 15px;
    top: 30px;
  }
}

div.header-links,
div.header-links>a {
  display: inline-block;
}

.action-menu-btn .navbar-nav {
  display: inline-block;
  flex: auto;
}

.action-menu-btn ul li {
  display: inline-block;
}

nav.navbar .collapse.navbar-collapse {
  display: block !important;
  text-align: right !important;
}

.cross_icon {
  float: right;
  cursor: pointer;
}

.fas {
  color: #fff;
}

.cross_icon i.fa.fa-times.fas {
  color: #fff;
  opacity: 1;
}

.span-heading {
  margin-right: 35px;
}

.location-div {
  cursor: pointer;
}

.active-location {
  background: #042053;
}

.active-tab .checkout-card {
  background: var(--theme_color) 4f;
  color: black;
  border: 1px solid var(--theme_color) 4f;
  transition: all 0.5s;
}

.card-info-input.StripeElement--empty.StripeElement {
  background: #e8e8e8;
  padding: 12px;
  border-radius: 5px;
  color: black;
  border: 1px solid #8a8a82;
}

label.location-heading {
  text-transform: capitalize;
}

.checkbox-main a {
  font-weight: 400;
}

.box-stepper .steps ul {
  overflow: hidden;
}

/* .steps ul li:first-child:after{
        display: none;
  } */
.box-stepper {
  margin-left: 20px;
}

.box-stepper .steps ul li div {
  color: #999;
  padding: 10px 0px 10px 12px;
  position: relative;
}

.box-stepper .steps ul li div span {
  font-size: 16px;
  font-weight: 500;
}

.box-stepper .steps ul li:first-child div {
  padding-left: 15px;
}

.box-stepper .steps ul li.done div {
  color: var(--theme_color);
}

.box-stepper .steps ul li.active div {
  color: var(--theme_color);
}

.stepper-icon {
  padding-right: 5px;
}

.box-stepper .step-component {
  margin-top: 3%;
}

.box-stepper .btn-component {
  display: flex;
  justify-content: space-between;
}

.stepper-title-wrappper {
  display: flex;
}

.arrows {
  padding-right: 10px;
}

.checkout-hide-footer {
  display: none;
}

.order-summary-detail.active {
  display: block;
  position: fixed;
  border: 0;
  background: white;
  bottom: 64px;
  width: 100%;
  left: 0;
  right: 0;
  z-index: 9;
  transition: all 0.5s;
  border-top-left-radius: 0.4375rem;
  border-top-right-radius: 0.4375rem;
}

.shape,
.backdrop {
  display: block;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(315deg,
      #63a4ff 0%,
      var(--theme_color) 57 74%);
  position: fixed;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
}

.view-test-main-row {
  padding-top: 50px;
}

.above-fold-text {
  text-align: justify;
  font-size: 12px;
  color: gray;
}

.backdrop.loader-active {
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.backdrop-cart.loader-active {
  opacity: 1;
  pointer-events: auto;
  z-index: 999;
}

.beat-loader {
  height: 100vh;
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  z-index: 99999999;
  overflow: hidden;
}

.shaded.shaded {
  opacity: 1;
  pointer-events: auto;
}

.order-summary-detail h2 {
  font-size: 15px;
  padding: 25px 9px;
  border-bottom: 1px solid #cec0c0;
}

.order-summary-detail {
  display: none;
}

.order-summary-detail.active {
  display: block;
}

.loader-button {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.order-summary-detail.active .promo-wrapper {
  box-shadow: none;
}

.order-summary {
  /* margin-top: 28.8%; */
  background: #f1fbfd;
  overflow: hidden !important;
  border: none !important;
}

.checkout-wrapper1 {
  display: none !important;
}

.search-location i {
  position: absolute;
  top: 16px;
  right: 15px;
  font-weight: 100;
  font-size: 10px;
  color: #77aef1 !important;
  cursor: pointer;
}

form.searching {
  padding: 0 13px;
  display: flex;
}

@media (min-width: 320px) and (max-width: 766px) {
  iframe.freshwidget-embedded-form.custom_class {
    width: 100%;
    height: 100%;
    margin-bottom: 200px;
  }

  .upsells-title {
    font-size: 11px;
    font-weight: 600;
  }



  .chooseLocation-tbtn {
    width: 100%;
  }

  .action-btn .subtotal span {
    font-size: 14px;
  }

  .action-plus-btn .subtotal span {
    font-size: 14px;
  }

  body button.btn.btn-primary.btn-lg.checkout_button.custom-btn-id {
    padding: 15px 8px;
    font-size: 16px;
  }

  .tile-card .card-points,
  .tile-card .cart-text {
    height: auto;
  }

  .section-gap.checkout-main {
    padding: 0;
  }

  .order-sumry-mobile.order-summary-detail.active {
    border: 1px solid #d2d2d21c;
  }

  .btn-component {
    display: flex;
    justify-content: space-between;
  }

  /* .col-md-5.secure-checkout-section2,
  .gift-wrapper {
    display: none;
  } */

  .secure-checkout {
    padding: 0;
  }

  .selected-checkbox {
    padding: 5px 15px;
    font-size: 13px;
  }

  .steps ul li {
    width: 33.33%;
    text-align: center;
  }

  .login-ask span {
    font-size: 15px;
  }

  .checkout-heading span {
    font-size: 14px;
    font-weight: 500;
  }


  p.mb-0.login-ask {
    font-size: 13px !important;
  }

  .stepper-title-wrappper {
    display: block;
  }

  .box-stepper .steps ul li div {
    padding: 4px 0px 4px 0px;
    position: relative;
  }

  .arrows {
    position: absolute;
    left: 0px;
    top: 15px;
  }

  .box-stepper {
    margin-left: 0px;
    margin-bottom: 20px;
  }

  .stepper-icon {
    display: block;
  }

  /* .std-main1 .container {
    padding: 0 !important;
  } */

  .checkout-logo-position.mt-2 {
    text-align: left !important;
  }

  .box-stepper .steps ul li:first-child div {
    padding-left: 0;
  }

  /* .menu-item .dropdown-menu.show {
        transform: translate3d(-68px, 48px, 0px) !important;
    } */

  .std-div-dotImg,
  .std-div-dotImg1,
  .std-div-dotImg2,
  .std-div-dotImg3 {
    height: 290px;
    margin: 30px 0;
  }

  body .mobile-screen {
    display: block;
  }

  body .desktop-screen {
    display: none;
  }

  .card-btn-main {
    padding: 10px 0px 10px;
  }

  .healthcare-div1 .detail {
    text-align: center;
    padding-bottom: 20px;
    margin-top: 10%;
  }

  h2.question-title {
    margin-top: 8%;
  }

  .section-gap {
    padding: 20px 0;
  }

  .section-gap.test {
    padding-top: 30px;
  }

  #weight {
    flex-direction: column;
  }

  #order-weight {
    order: 1;
  }

  .view-tests-btn.text-center {
    margin-bottom: 55px;
  }

  .custom-navbar .navbar-nav li {
    padding-left: 8px;
    padding-top: 0px;
  }

  .cart-view-card .card-body-1 {
    padding: 14px 15px;
  }

  .cart-view-card .card-points {
    height: auto;
  }

  .cart-view-card .cart-text {
    height: auto;
  }

  .login-footer .btn {
    width: 100%;
  }

  .login-footer {
    padding: 20px 0;
  }

  .cart-btn-section3,
  .cart-btn-section2,
  .cart-btn-section1 {
    margin-top: 10px;
  }

  .promo-wrapper {
    order: 2;
  }

  .cart-table-card {
    order: 1;
  }

  .secure-checkout-main {
    position: relative;
  }

  .loc-btm-btn {
    min-height: 56vh;
  }

  .secure-checkout-section1 {
    order: 3;
  }

  .order-lg-custom-2 {
    order: 2;
  }

  .order-lg-custom-3 {
    order: 3;
  }

  .container.row-fixed {
    margin-top: 40px;
  }

  .std-main {
    padding: 20px 0 !important;
  }

  .htw-second-nav {
    padding: 10px 0;
  }

  .order-lg-custom-1 {
    order: -1;
    padding-top: 30px;
  }

  .my-testimonial .react-multi-carousel-list {
    margin-bottom: 0px;
  }

  li.react-multi-carousel-item.react-multi-carousel-item--active {
    margin-right: 20px;
    width: 290px !important;
  }

  .card-testimonial {
    max-width: 290px;
  }

  .numb-text {
    font-size: 0px;
  }

  .checkout-wrapper1 {
    display: none;
  }

  .no-data-visit span {
    font-size: 15px;
    font-weight: lighter;
    margin: auto;
    color: #aab0b5;
    position: relative;
    top: 155px;
  }

  .menu-item-tests .fade-test-nav {
    font-size: 14px;
    margin-left: 0;
    padding: 0 3px;
  }
}

@media screen and (max-width: 379px) {
  .navbar-brand img {
    width: 110px;
  }

  /* body .menu-wrapper .container .btn {
    padding: 8px 12px;
  } */
  body .menu-wrapper .container .btn {
    padding: 6px 12px;
    font-size: 12px;
  }

  .order-sumry-mobile.order-summary-detail.active {
    border: 1px solid #d2d2d21c;
  }

  .login-ask span {
    font-size: 15px;
  }


  .checkout-wrapper1 {
    display: none;
  }
}

@media screen and (max-width: 340px) {


  .checkout-wrapper1 {
    display: none;
  }

  .order-sumry-mobile.order-summary-detail.active {
    border: 1px solid #d2d2d21c;
  }

  .login-ask span {
    font-size: 12px;
  }


}

#final-price {
  display: none;
}

.appointment-action-btn .btn {
  padding: 8px 8px;
  font-size: 14px;
  margin: 20px 0 0;
}

.apt-price {
  font-weight: 400;
}

.btn {
  cursor: pointer;
}

.covid-upgrade {
  padding: 10px 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  border-top: 1px solid #e9eaea;
}

.upgrade-btn .btn {
  padding: 4px 10px;
  font-size: 14px;
  background: #77acf1;
  margin-top: -8px;
}

.upgrade-text {
  margin-top: 8px;
  font-weight: 500;
}

.login-section .section-gap {
  padding: 60px 0;
  width: 100%;
}

.form-content {
  display: block;
  max-width: 540px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0px 8px 20px 0px rgb(95 109 179 / 20%);
  padding: 40px 12px;
  border-radius: 7px;
}

.login-form {
  display: block;
  max-width: 530px;
  margin: 0 auto;
  background: #fff;
  box-shadow: 2px 20px 20px 13px rgb(95 109 179 / 20%);
  padding: 40px 96px;
  border-radius: 5px;
  margin-top: 76px;
}

button.btn.btn-lg-primary.login-btn {
  padding: 2% 0;
  margin: 0;
  border-radius: 6px;
}

button.btn.btn-lg-primary.login-btn:hover {
  background: #fff;
}

.f-body .form-wrapper {
  max-width: 430px;
  margin: 20px auto 50px auto;
}

.btn-login .btn {
  width: 100%;
}

.react-tel-input .flag-dropdown {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 0;
  background-color: #fff !important;
  border: none !important;
}

span.my-span-login.text-danger.mt-2 {
  position: relative;
  top: -7px;
}

.sucess-page-logo {
  position: fixed;
  top: 0;
  padding: 20px 0;
  left: 0;
  right: 0;
  z-index: 9;
  border-bottom: 1px solid #00000024;
}

.sucess-page-logo img {
  max-width: 200px;
  margin: 0 auto;
  display: block;
}

.login-page-logo img {
  margin: 24px auto;
  width: 100%;
  height: 100%;
}

.login-page-logo {
  cursor: pointer;
  max-width: 200px;
  margin: auto;
}

nav.sidenav---sidenav---_2tBP.sidenav---collapsed---LQDEv {
  position: fixed;
  background: #77acf1;
  transition: 0.5s all;
}

nav.sidenav---sidenav---_2tBP.sidenav---expanded---1KdUL {
  position: fixed;
  padding-top: 50px;
  background: #77acf1;
  transition: 0.5s all;
}

table.table-visits td {
  color: #525f7f;
  font-size: 17px;
  font-weight: 100;
  padding: 20px 0;
  text-align: left;
}

table.table-visits th {
  font-size: 13px;
  white-space: nowrap;
  font-weight: 500;
  color: #525f7f;
  letter-spacing: 1px;
  text-align: left;
  padding: 14px 0;
}

table.table-visits tr {
  border-bottom: 1px solid #dce4f8;
}

.main-sidecontent {
  margin-left: 82px;
  transition: 0.5s all;
  padding: 0 15px;
  display: block;
  width: calc(100% - 82px);
  overflow: auto;
}

.main-sidebar-content {
  margin-left: 241px;
  transition: 0.5s all;
  padding: 0px 85px;
}

span.side-text {
  font-size: 14px;
  margin: 0px 18px;
  position: absolute;
  margin-left: 15px 10px;
}

button.btn.btn-lg-success.btn-view-test {
  color: #fff;
  background-color: #2dce89e3;
  border-color: #2dce8926;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  font-size: 10px;
  font-weight: 700;
}

button.btn.btn-lg-success.btn-view-test:hover {
  color: #fff;
  background-color: #26af74;
  border-color: #24a46d;
}

button.btn.btn-lg-primary.btn-view-test {
  color: #fff;
  background-color: #399eff;
  border-color: #399eff;
  box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
  font-size: 10px;
}

button.btn.btn-lg-primary.btn-view-test:hover {
  color: #fff;
  background-color: #138bff;
  border-color: #0685ff;
}

#right,
.table.table-visits td+td,
.table.table-visits th+th {
  text-align: left;
}

.table.table-visits td {
  margin-bottom: 1rem;
}

body nav a {
  color: #fff !important;
}

body nav .sidenav---navtext---1AE_f ::before {
  color: black !important;
}

body nav .side-text ::before {
  color: black !important;
}

body nav .sidenav---expanded---1KdUL .sidenav---navtext---1AE_f {
  color: #fff !important;
}

.page-item .page-link {
  background: #0685ff;
}

.page-item .page-link:hover {
  color: #0685ff !important;
  background: #fff;
}

nav.custom-sidebar {
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
}

.card-result {
  margin-bottom: 30px;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);

  border: none !important;
}

p.card-title.card-result-title {
  font-size: 20px;
  font-weight: bold;
  text-align: right;
}

.card-result-test {
  margin-bottom: 1.6rem;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.5;
  color: #7985a5;
}

.card-result-group {
  color: #8898aa !important;
}

button.btn.btn-lg-primary.btn-view-test.btn-refresh {
  font-size: 18px;
  padding: 2px 15px;
  text-transform: uppercase;
}

button.btn.btn-lg-primary.btn-view-test.btn-doctor-note {
  font-size: 16px;
  padding: 2px 15px;
  text-transform: uppercase;
}

button.btn.btn-lg-primary.btn-view-test.btn-refresh:hover,
.btn.btn-lg-primary.btn-view-test.btn-doctor-note:hover {
  color: #0685ff !important;
  background: #fff;
}

.close {
  border: none;
  background: #fff;
}

.note-title {
  margin-bottom: 0.5rem;
  /* font-family: inherit; */
  font-weight: 100;
  line-height: 1.5;
  color: #32325d;
  font-size: 25px;
}

.note-text {
  font-weight: 300;
  line-height: 1.7;
  font-size: 21px;
  color: #728598;
}

p.login-form-links {
  font-size: 93%;
  font-weight: 400;
  cursor: pointer;
  color: #3b444e !important;
}

p.login-form-links:hover {
  color: black !important;
}

p.text-center.forgot-h-p {
  font-size: 16px;
  color: #635a5ab0;
}

button.btn.mt-3.btn-lg-primary.create-acc-btn {
  background: #0da2fd;
  border-radius: 30px;
}

button.btn.mt-3.btn-lg-primary.create-acc-btn:hover {
  background: #fff;
  border-radius: 30px;
}

.no-data-visit span {
  font-size: 36px;
  font-weight: lighter;
  margin: auto;
  color: #aab0b5;
  position: relative;
}

.test-result {
  display: flex;
}

.test-result .btn {
  margin-left: 10px;
}

p.await-text {
  font-size: 22px;
  font-weight: 100;
  color: #675c5c;
}

/* //success page  */
.text-main-success {
  margin: auto;
  max-width: 254px;
  /* margin-bottom: 3%; */
}

.text-main-success .title {
  font-size: 26px;
  font-weight: 300;
  text-align: center;
}

.work-card-inner {
  display: flex;
  align-items: center;
  /* flex-wrap: wrap; */
}

.work-card.success-card {
  max-width: 800px;
  margin: 0 auto 0px auto;
  -webkit-box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%),
    0 2px 10px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  padding: 30px 28px;
  border-radius: 10px;
}

.work-card.success-card .work-card-img {
  position: relative;
  display: inline-block;
  width: 110px;
  top: 12px;
}

.work-card.success-card .work-card-description {
  padding-left: 20px;
  padding-top: 0;
}

.work-card.success-card .work-title {
  margin-top: 0px;
}

.work-card.success-card .work-card-img:after {
  content: "";
  position: absolute;
  top: -10px;
  width: 70px;
  height: 70px;
  border-radius: 50%;
  left: 3px;
  z-index: 1;
}

/* //profile */
.patient-profile {
  padding: 3% 10%;
}

.patient-name p {
  text-align: center;
  color: #171313;
  font-size: 28px;
  font-weight: 300;
}

.card.profile-card {
  border: none !important;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  padding: 20px;
}

.card.profile-card1 {
  border: none !important;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  padding: 20px;
  height: 487px;
}

.profile-img img {
  width: 50% !important;
  border-radius: 100% !important;
}

.profile-img {
  text-align: center;
}

h3.mt-3 {
  text-align: center;
}

.profile-details ul li {
  list-style: none;
  padding: 9px 0;
}

.profile-details ul li i {
  padding-right: 10px;
}

.profile-details {
  border-top: 1px solid #efefef;
  padding: 20px 0 0;
}

.add-height {
  height: 487px;
}

.work-card-logo i {
  color: #fff;
  font-size: 30px;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.work-card-logo {
  border-radius: 100%;
  background: var(--theme_color);
  height: 80px;
  max-width: 80px;
  width: 100%;
}

/* time zone  */
.days-content.active {
  background: var(--theme_color);
  border-color: var(--theme_color);
  border-radius: 4px;
}

.days-content.active h4 {
  background: var(--theme_color);
  color: #ffff !important;
}

.active.time-content span i,
.active.time-content h4 {
  color: #fff !important;
}

.active.time-content {
  background: var(--theme_color);
}

.days-content h4 {
  color: var(--theme_color);
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.date-time-wrapper h2 {
  text-align: center;
  color: #483f3f;
  margin-bottom: 30px;
  font-size: 21px;
  font-weight: 400;
}

.days-content-wrapper,
.time-content-wrapper {
  display: flex;
  width: 100%;
  cursor: pointer;
  flex-wrap: wrap;
}

.days-content {
  width: 10%;
  padding: 10px 0px;
  text-align: center;
  background: white;
  margin: 1px 12px 24px;
  height: 65px;
  user-select: none;
 

}
.days-content-disabled{ 
  pointer-events: none;
  opacity: 0.4;
}
.days-content:not(.active):not(.arrow-content):hover{
   background-color: #3690ff2f;
   border-radius:5px ;
}

.time-content {
  padding: 12px 12px;
  margin: 0px 10px 15px 0;
  max-width: calc(33.33% - 10px);
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid var(--theme_color);
  border-radius: 4px;
  justify-content: center;
}

.time-content:not(.active):hover{
  background-color: #3690ff3f;
}

.time-circle i {
  color: var(--theme_color);
  font-size: 18px;
}

.time-content h4 {
  font-weight: 500;
  margin-bottom: 0;
  padding-left: 5px;
  font-size: 14px;
  color: var(--theme_color);
}

@media (min-width: 786px) and (max-width: 1024px) {
  .time-content {
    padding: 12px 14px;
    max-width: calc(50% - 10px);
  }

  .time-circle i {
    font-size: 16px;
  }

  .time-content-wrapper .time-content h4 {
    padding-left: 3px;
    font-size: 9px;
    color: var(--theme_color) !important;
  }

  .checkout-wrapper1 {
    display: none;
  }

  .order-sumry-mobile.order-summary-detail.active {
    border: 1px solid #d2d2d21c;
  }
}

.time-table {
  float: right;
}

.closed-lunch .closing-timing {
  float: right;
  font-style: italic;
}

.hide-hours {
  color: var(--theme_color);
  cursor: pointer;
}

.hidden-hours {
  display: none;
}

.day span {
  font-size: 18px;
  font-weight: 500;
}

.closed-lunch .closing-timing {
  float: right;
  font-weight: 400;
}

.closed-lunch span {
  font-style: italic;
  font-weight: 500;
}

.timing-wrapper li:nth-child(2n) {
  background-color: #f0f8ff;
}

.timing-wrapper li {
  border: none;
}

.step {
  list-style: none;
  margin: 0.2rem 0;
  width: 100%;
}

.step .step-item {
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}

.step .step-item:not(:first-child)::before {
  background: #0069d9;
  content: "";
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item:not(:nth-child())::before {
  background: #0069d9;
  content: "";
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}

.step .step-item a {
  color: #acb3c2;
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}

.step .step-item a::before {
  background: #0069d9;
  border: 0.1rem solid #fff;
  border-radius: 50%;
  content: "";
  display: block;
  height: 0.9rem;
  left: 50%;
  position: absolute;
  top: 0.2rem;
  transform: translateX(-50%);
  width: 0.9rem;
  z-index: 1;
}

.step .step-item.active a::before {
  background: #fff;
  border: 0.1rem solid #0069d9;
}

.step .step-item.active~.step-item::before {
  background: #e7e9ed;
}

.step .step-item.active~.step-item a::before {
  background: #e7e9ed;
}

body .modal-backdrop {
  width: 100%;
  height: 100%;
}

.header-icons {
  position: relative;
  top: -6px;
}

.steeper-head .completed {
  background-color: rgb(44 106 217) !important;
}

.steeper-head .completed {
  background-color: rgb(44 106 217) !important;
}

.steeper-head .completed {
  background-color: rgb(44 106 217) !important;
}

.RFS-StepCircle-d8 {
  background-color: rgb(224, 224, 224) !important;
}

.RFS-StepCircle-d4 {
  background-color: rgb(224, 224, 224) !important;
}

.RFS-StepCircle-d4.active {
  background-color: rgb(216 213 213) !important;
}

.steeper-head .active {
  background: #cec9c1 !important;
}

.steeper-head .active {
  background: #cec9c1 !important;
}

.steeper-head.completed:hover {
  background-color: rgb(119 173 241) !important;
}

.steeper-head .completed:hover {
  background-color: rgb(119 173 241) !important;
}

.steeper-head .completed:hover {
  background-color: rgb(119 173 241) !important;
}

h4.card-test-price {
  position: relative;
  float: right;
  top: -35px;
}

.tile-card {
  /* border: none; */
  background-clip: border-box;
  border: 0 solid #f1f5f7 !important;
  border-radius: 0.25rem !important;
  box-shadow: 0 2px 4px rgb(0 0 0 / 8%);
  margin-bottom: 30px;
  transition: transform 0.5s ease-in-out;
  cursor: pointer;
}

.tile-card:hover {
  transform: translateY(-10px);
}

.card-points {
  height: 60px;
}

.last-card-std .card-points,
.covid-card-test .card-points,
.allergy-card-test .card-points {
  height: auto;
}

span.test-names,
.view-all-test {
  position: relative;
  top: 3px;
  left: 10px;
}

.view-test-main {
  padding: 0 15px;
}

a.summer-token {
  color: #fff;
  cursor: pointer;
}

a.summer-token:hover {
  color: #fff;
  text-shadow: -1px 0px 2px #fff;
  transition: all 0.5s;
}

h6.card-title.visit-appointment {
  color: #2e6cbd;
  font-size: 16px;
  font-weight: 100;
  padding: 20px 0;
}

.result-mob-btn button {
  font-size: 72% !important;
  padding: 7px 13px;
}

.mob-visit-card {
  border-radius: 0.25rem;
  box-shadow: 0 15px 35px rgb(50 50 93 / 10%), 0 5px 15px rgb(0 0 0 / 7%);
  border: none !important;
}

.loc-icon {
  color: var(--theme_color);
}



span.d-block img {
  width: 17px;
  height: auto;
}

span.order-summary-img.d-block img {
  width: 27px;
}

p.mb-0.login-ask {
  float: left;
  position: relative;
  top: 4px;
  left: -14px;
}

.login-ask span {
  font-size: 16px;
  color: black !important;
}

span.RFS-Label.RFS-Label-d3,
span.RFS-Label.RFS-Label-d7,
span.RFS-Label.RFS-Label-d11 {
  font-size: 16px;
}

.checkout-logo img {
  height: auto;
  width: 191px;
}

.order-sumry-mobile.order-summary-detail.active {
  display: block;
  position: relative;
  bottom: 0;
  border-radius: 0;
  background: #f1fbfd;
  float: left;
  height: 100%;
  border: 1px solid gainsboro;
  margin-bottom: 20px;
}

.stepper {
  display: flex;
}

.stepper span {
  font-weight: 500;
  position: relative;
  /* left: -16px; */
}

.stepper span i {
  position: relative;
  top: 0;
  margin: 0 4px;
}

.blink-step {
  color: #000;
}

.fade-step {
  color: #21252982;
}

.completed-step {
  color: var(--theme_color);
}

.checkout-logo-wrapper {
  border-bottom: 1px solid #dadada;
}

.checkout-logo-position {
  padding: 15px 0;
}

/* .checkout-logo-position.mt-2 {
    text-align: center;
} */

img.check-pic {
  height: 24px;
  width: 24px;
}

.col-md-6.secure-checkout-section2 {
  margin-top: 8%;
}

a.mob-order-summary {
  font-weight: 400;
  font-size: 16px;
}

button.btn.btn-lg-primary.payment-btn {
  margin-left: 15px;
}

span.location-head {
  font-size: 20px;
  font-weight: 500;
  color: var(--theme_color);
  margin-left: 0;
}

span.form-label {
  position: relative;
  color: #555;
  top: 18px;
  font-size: 16px;
  font-weight: 400;
}

.parent-insurance {
  display: flex;
  gap: 20px;
}

.form-border {
  border: none;
}



.row.border-div {
  border-bottom: 1px solid #d4d4d4;
  margin: 0;
}

.row.border-div1 {
  border: 1px solid #d4d4d4;
  margin: 0;
}

.modal-dialog.custom-modal.success-model.refund-policy {
  height: 600px;
}

.toggle-div {
  padding: 15px 12px 0;
}

span.test-nav {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 3%;
  position: relative;
  top: 8px;
  color: var(--theme_color);
}

span.fade-test-nav {
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  margin-left: 3%;
  position: relative;
  top: 8px;
  /* color: var(--theme_color); */
}

.login-navbar {
  border: 1px solid #e6e6e6;
  padding: 2px 18px;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 16px;
  cursor: pointer;
}

.login-navbar:hover,
.call-us:hover {
  color: rgba(0, 0, 0, 0.88);
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.06);
  box-shadow: none;
}

span.login {
  font-weight: 500;
}

span.need-help {
  font-weight: 500;
}

span.font-text {
  border-bottom: 1px solid;
}

.react-toggle--checked .react-toggle-track {
  background-color: var(--theme_color) !important;
}

.react-toggle--checked .react-toggle-thumb {
  border: var(--theme_color) !important;
}

.row.form-border-row.main-ero {
  margin: 0;
}

.menu-item-small-screen {
  text-align: center;
}

.chooseLocation-tbtn {
  margin-right: 10px;
}

.return-to-home {
  display: inline-block;
  position: relative;
  top: 4px;
  color: var(--theme_color);
  text-decoration: underline;
}

span.text-danger.error-text {
  font-size: 13px;
}

.bullet-position {
  text-align: left;
}

.treat {
  font-size: 13px;
  color: #565f5f;
}

.call-us.checkout-call {
  float: right;
}

.add-icon i {
  font-size: 32px;
  color: #3265bd;
}

.appointment-arrow {
  font-size: 30px;
  color: var(--theme_color);
  margin-top: 15px;
  cursor: pointer;
}

/* .checkout-card.card-covid {
    height: 205px;
} */

.call-us.checkout-call {
  float: right;
  border: 1px solid #e6e6e6;
  padding: 2px 18px;
  border-radius: 15px;
  -webkit-box-shadow: rgb(0 0 0 / 5%) 0px 2px 16px;
  box-shadow: rgb(0 0 0 / 5%) 0px 2px 16px;
  cursor: pointer;
  margin: 0 23px;
}

.border-div1.place-div {
  border-right: none;
}

.border-div1 {
  border: 1px solid #999999;
}

.form-custom {
  border-bottom: none !important;
}

.cart-update-news {
  padding: 10px 0 0;
  background-color: #ecefff;
  color: var(--theme_color);
  font-weight: 600;
}

.cart-items .cart-img img {
  width: 36px;
}

.cart-item-inner {
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  width: 100%;
}

.cart-items {
  display: flex;
  padding: 2px 15px 1px;
  border-bottom: 1px solid #e0dddd80;
}

.cart-img {
  padding-right: 20px;
}



.cart-items .f-price {
  color: var(--theme_color);
  font-weight: 400;
  padding-right: 7px;
  font-size: 12px !important;
  letter-spacing: 0.05rem;
}

.original-price {
  color: #9ea0a2;
  font-size: 14px;
}

.delete-item i {
  font-size: 22px;
  color: #a2a2a2;
  cursor: pointer;
}

.delete-item {
  position: absolute;
  right: 20px;
  top: 9px;
}

.close-cart {
  position: absolute;
  right: 20px;
  top: 0px;
}

.action-btn {
  display: flex;
  border-top: 1px solid #e4e8ff;
  margin-top: -1px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  padding: 20px 15px;
}

.action-plus-btn {
  display: flex;
  border-top: 1px solid #e4e8ff;
  margin-top: -1px;
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: #fff;
  padding: 20px 15px;
}

.continue-checkout {
  max-width: 500px;
  width: 100%;
  padding-left: 20px;
}

.continue-checkout button {
  width: 100%;
  background-color: var(--theme_color);
  border-radius: 0;
  border: none;
  padding: 21px 0px;
  border-radius: 10px;
}

.continue-plus-checkout {
  max-width: 500px;
  width: 100%;
  padding-left: 20px;
}

.continue-plus-checkout button {
  width: 100%;
  background-color: var(--theme_color);
  border-radius: 0;
  border: none;
  padding: 21px 0px;
  border-radius: 10px;
}

.action-btn .subtotal-text {
  color: var(--theme_color);
}

.action-btn .subtotal {
  max-width: 110px;
  width: 100%;
}

.action-btn .final-price {
  color: #a2a2a2;
}

.action-plus-btn .subtotal-text {
  color: var(--theme_color);
}

.action-plus-btn .subtotal {
  max-width: 110px;
  width: 100%;
}

.action-plus-btn .final-price {
  color: #a2a2a2;
}

.order-summary-img img {
  width: 50px;
}

.order-summary-label {
  padding-left: 20px;
}

.shopping-cart {
  padding: 6px 11px;
  cursor: pointer;
}

.shopping-cart i {
  font-size: 21px;
}

.shopping-cart .count {
  font-size: 11px;
  background: var(--theme_color);
  color: #fff;
  padding: 0px 5px;
  border-radius: 50%;
  position: relative;
  top: -10px;
}

.action-btn .final-price {
  color: #a2a2a2;
}

.action-plus-btn .final-price {
  color: #a2a2a2;
}

.wave-container-top img {
  position: absolute;
  top: -50px;
  height: 50px;
  width: 100%;
  left: 0;
  right: 0;
}

.wave-container-bottom img {
  position: absolute;
  bottom: -100px;
  transform: rotate(180deg);
  width: 100%;
  height: 100px;
  left: 0;
  right: 0;
}

.health-insurance-toggle-new {
  display: table;
}

.border-div-pas {
  border: 1px solid #e6e6e6;
  /* border-bottom: none; */
  padding: 0px;
  position: relative;
  top: -153px;
}

.form-inner .react-tel-input {
  font-size: 16px;
}

span.disease-icon.swab {
  position: relative;
  top: 4px;
}

img.hours {
  position: relative;
  top: -4px !important;
}

h2.cart-heading.lym-disease {
  font-size: 17px;
}

/* Mega menu */
.mega-dropdown {
  float: left;
  overflow: hidden;
  /* width: 100%; */
}

button.am_i_eligible {
  background: var(--theme_color);
  border: 1px solid transparent;
  font-family: jost !important;
  border-radius: 5px;
  font-weight: 500;
  cursor: pointer;
  display: inline-flex;
  position: relative;
  text-decoration: none;
  touch-action: manipulation;
  -webkit-box-pack: center;
  justify-content: center;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  padding: 7px;
  transition: box-shadow 0.4s ease 0s;
  color: white !important;
}

button.am_i_eligible_join {
  padding: 18px 18px 17px;
  margin-top: 30px;
  border-radius: 5px;
  font-weight: 500;
  line-height: 8px;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  background-color: var(--theme_color);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  border: none;
}

.step_para {
  color: #ffffff;
  margin-top: 4%;
}

.get_started h1 {
  color: #fff;
  font-size: 48px;
  margin-bottom: 20px;
}

.get_started1 {
  background-color: #368fff;
}

.get_started {
  width: 100%;
  background-color: #368fff;
  text-align: center;
  padding: 17px 1.5rem;
}

.get_started11 {
  background-color: #fff;
  background-image: none;
}

.get_started22 {
  background-color: #fff;
  background-image: none;
}

.get_started11 h1 {
  color: var(--theme_color);
}

button.am_i_eligible>i {
  display: none;
}

button.am_i_eligible_join>i {
  display: none;
}

.mega-content {
  opacity: 0;
  position: absolute;
  background-color: #fff;
  border: 1px solid #d8e2e6;
  width: 100%;
  left: 0;
  /* top: 0px; */
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  overflow: hidden;
  transition: 0.5s all;
  transition: transform 0.1s cubic-bezier(0.2, 0.25, 0, 1),
    opacity 0.1s cubic-bezier(0.2, 0.25, 0, 1);
  transform: scaleY(0);
  transform-origin: 100% 0%;
  margin-top: 0;
}

.mega-content .container-megamenu {
  max-width: 1120px;
  margin: 0 auto;
}

.mega-content {
  opacity: 1;
  transform: scaleY(1);
  transition: transform 0.4s cubic-bezier(0.2, 0.25, 0, 1),
    opacity 0.4s cubic-bezier(0.2, 0.25, 0, 1);
}

/* Mega Inner Menu */
.mega-inner-menu {
  padding: 12px 15px;
  border-right: 1px solid #e6e6e6b8;
}

/* Create three equal columns that floats next to each other */
.mega-inner-menu a {
  display: block;
  padding: 10px 0;
  color: #3a3a3a !important;
  text-align: left;
}

.tabcontent {
  display: none;
  padding: 20px 0px;
}

.mega-inner-items {
  text-align: left;
  position: relative;
}

.mega-inner-items:after {
  content: "";
  position: absolute;
  right: 0;
  border-right: 1px solid #e6e6e6b8;
  min-height: 306px;
  top: 0;
  bottom: 0;
  left: 0;
}

.mega-mobile-inner-items:after {
  display: none;
}

.mega-inner-items h4 {
  font-size: 16px;
}

.mega-inner-menu a img {
  float: right;
  width: 20px;
}

.mega-dropdown .mega-content .show-first-meganav {
  display: block;
}

.mega-inner-items a {
  display: block;
  color: #3a3a3a !important;
  line-height: 2.8;
  font-size: 14px;
}

.megamobile-menu {
  display: none;
}

/* mobile view */
button.navbar-toggler.megamobile-menu i {
  color: #3d78d0;
  font-size: 30px;
}

.Inner__sidebar {
  z-index: 1002;
}

.mega__mobile__close-btn {
  float: right;
  font-size: 23px;
  line-height: 0.7 !important;
}

.show-mega-menu {
  display: block;
}

.hide-mega-menu {
  display: none;
}

a.mega-inner-menu2.tablinks.active {
  color: #78aef0 !important;
}

.inner-mobile-menu-items span {
  line-height: 3;
  border-bottom: 1px solid #efefef;
}

.order-summary-img img {
  width: 50px;
}

.order-summary-label {
  padding-left: 20px;
}

.shopping-cart {
  padding: 6px 11px;
  cursor: pointer;
}

.shopping-cart i {
  font-size: 21px;
}

.shopping-cart .count {
  font-size: 9px;
  background: var(--theme_color);
  color: #fff;
  font-weight: 800;
  padding: 0px 5px;
  border-radius: 50%;
  position: relative;
  top: -10px;
}

.sidenav---navtext---1AE_f {
  position: relative !important;
  top: -4px !important;
}

/* .remove-waves {
    background: transparent;
    padding-bottom: 100px;
}
.remove-waves .wave-container-top,.remove-waves .wave-container-bottom {
    display: none !important;
} */
.sidenav---sidenav-nav---3tvij>.sidenav---sidenav-navitem---uwIJ->.sidenav---navitem---9uL5T {
  position: relative;
  display: block;
  line-height: 50px;
  height: 50px;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  text-align: center !important;
}

.gender-option {
  display: flex;
  align-self: center;
}

.form-label-2 {
  top: 5px;
}

.option-inner {
  max-width: 70px;
  width: 100%;
  margin-right: 19px;
}

.option-inner.option-female {
  max-width: 82px;
}

.option-inner label {
  display: flex;
  align-items: center;
}

.modal-dialog.custom-modal.success-model.insurance-modal {
  height: 233px;
  font-size: 15px;
  font-weight: 100;
}

.schedule-btn a:hover {
  border: 1px solid;
  color: var(--theme_color) !important;
  background: #fff !important;
}

.work-card-schedual {
  position: relative;
  width: 80%;
  padding-left: 18px;
}

.cart-heading.sleep-heading {
  font-size: 18px;
}

p.upsell-head {
  color: #000 !important;
  letter-spacing: 0.05em;
  font-size: 12px;
}

.testing-btn.text-primary {
  background: #7da9db59;
  padding: 5px 12px;
  border-radius: 5px;
  margin: 22px 0;
  font-weight: 500;
}

.appointment-price-text {
  color: #3265bd;
}

.allergy-popup .modal-content {
  height: 180% !important;
}

.allergy-view-all {
  margin-top: -15px;
}

@media (max-width: 768px) {
  .main-partner-pres-section span {
    position: unset !important;
    left: unset !important;
  }

  /* .checkout-card.card-covid {
        height: 195px;
    } */
  .first_h2 {
    color: #fff;
    margin-bottom: 22px;
    font-size: 1.6rem;
    line-height: 34px;
  }

  h2.second_h2 {
    font-size: 16px !important;
    line-height: 20px !important;
  }

  .steps-section__title .second_h2 {
    font-size: 19px;
  }

  h2.second_h2 {
    font-size: 16px;
  }

  button.am_i_eligible {
    margin-right: 5px;
    padding: 5px 6px;
    margin-right: 5px;
  }

  .allergy-popup .modal-content {
    height: 265% !important;
  }
}

.news-letter {
  margin-top: 11% !important;
}

.mega-inner-items p {
  color: #000000bd;
  font-size: 15px;
  font-weight: 500;
  padding: 3px;
  position: relative;
  top: 2px;
}

.mega-inner-items h6 {
  padding: 3px;
  position: relative;
  top: 2px;
}

.backdrop-cart {
  display: block;
  width: 100vw;
  height: 100vh;
  position: fixed;
  background: linear-gradient(180deg,
      #9cc9ec,
      #bee7ffbd,
      #ddefff,
      var(--theme_color));
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out;
  transition: opacity 0.2s ease-in-out;
  backdrop-filter: blur(2px);
}

.row.main-ero {
  padding-left: 11px;
}

.alert-data {
  border: 1px solid red;
}

#testimonial-section .row {
  /* padding: 0 15px !important; */
  position: relative;
}

#weight_loss_slider .row {
  position: relative;
}

#testimonial-section button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: -41px;
  background: #368fff;
}

#testimonial-section .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: -41px;
  background: #368fff;
}

/* .htw_testimonial #testimonial-section button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: -41px;
  background: #fff;
  top: -91px;
  left: 970px;
} */

/* .htw_testimonial #testimonial-section button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {

  left: auto;
  right: 65px;
  background: #fff;
  top: -85px;
} */

/* .htw_testimonial #testimonial-section .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: 97px;
  background: #fff;
  top: -93px;
} */

/* .htw_testimonial #testimonial-section .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  background: #fff;
  top: -85px;
  right: 10px;
} */

.react-multiple-carousel__arrow {
  z-index: 4;
}

.htw_testimonial #testimonial-section button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left:before,
.htw_testimonial #testimonial-section .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right:before {
  color: #000;
}

.htw_testimonial h2.text-testimonial {
  color: #fff;
}

#testimonial-section .react-multi-carousel-item {
  padding: 10px 0;
}

#testimonial-section .my-testimonial .react-multi-carousel-list {
  position: inherit !important;
}

#weight_loss_slider button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  position: absolute;
  left: -21px;
}

#weight_loss_slider button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  position: absolute;
  right: -21px;
}

#weight_loss_slider .react-multi-carousel-list {
  position: relative;
}

@media (max-width: 768px) {
  #testimonial-section button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 0px;
  }

  #testimonial-section .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 0px;
  }
}

#testimonial-section-partner .row {
  /* padding: 0 15px !important; */
  position: relative;
}

#testimonial-section-partner button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
  left: -41px;
}

#testimonial-section-partner .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
  right: -41px;
}

#testimonial-section-partner .react-multi-carousel-item {
  padding: 10px 19px;
}

#testimonial-section-partner .my-testimonial .react-multi-carousel-list {
  position: inherit !important;
}

@media (max-width: 768px) {
  #testimonial-section-partner button.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: 0px;
  }

  #testimonial-section-partner .react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: 0px;
  }
}

span.testing-btn-color {
  color: #659de5;
  background: #e2eefd;
  padding: 6px;
  font-weight: 600;
  border-radius: 5px;
}

span.testing-btn-color1 {
  background: #d6f3ea;
  color: #1ebb8c;
  padding: 6px;
  font-weight: 600;
  border-radius: 5px;
}

.tag-item-home-1 {
  font-size: 11px;
  background: #d6f3ea;
  color: #1ebb8c;
  padding: 5% 15%;
  font-weight: 600;
  border-radius: 6px;
}

.tag-item-home {
  font-size: 11px;
  background: #deeeff;
  color: #65a3ff;
  padding: 2% 10%;
  font-weight: 600;
  border-radius: 6px;
}

.tag-item-1 {
  font-size: 13px;
  background: #d6f3ea;
  color: #1ebb8c;
  width: 100%;
  display: block;
  padding: 5px 10px;
  font-weight: 600;
  text-align: center;
  border-radius: 6px;
}

.tag-item {
  font-size: 13px;
  background: #deeeff;
  color: #65a3ff;
  font-weight: 600;
  border-radius: 6px;
  width: 100%;
  text-align: center;
  display: block;
  padding: 5px 8px;
}

.home-sub {
  font-size: 13px;
}

.tag1 {
  text-align: right;
}

.tag2 {
  text-align: left;
}

.timing-content-hv {
  padding: 0px !important;
  display: inline-block;
  width: 55% !important;
  text-align: right;
}

.timing-content-hv2 {
  padding: 0px !important;
  display: inline-block;
  width: 45% !important;
  /* text-align: left; */
}

.card-bottom-btn i {
  font-size: 21px;
}

.action-btn-stripe .StripeElement {
  position: absolute !important;
  top: 16px !important;
  z-index: 999;
  width: inherit;
  left: 0;
  right: 0;
}

.paypal-payment {
  position: absolute;
  width: inherit;
  top: -27px;
  font-size: 13px;
  color: #89929a;
}

.paypal-payment1 {
  position: absolute;
  width: inherit;
  font-size: 13px;
  top: -49px;
  color: #777879;
  left: 0;
}

.checkout-secure-main {
  position: relative;
}

p.f-left {
  text-align: initial;
  color: #a2a2a2;
}

.save-30 {
  font-weight: 600;
}

.upsells-price {
  text-decoration: line-through;
}

.cart-sidebar {
  background: #fff !important;
}

.delete-icon {
  color: black !important;
  font-size: 18px !important;
}

.cart-head {
  font-size: 19px;
  font-weight: 500;
  color: black;
}

.cart-header.px-3.position-relative {
  border-bottom: 1px solid #e2e2e2;
}

div#upsells-card\  {
  overflow-x: auto;
  padding: 0 5px;
}

.price.profit-price {
  position: absolute;
  right: 38px;
  top: -2px;
}

.price.profit-price p {
  text-decoration: line-through;
}

/* span.text-secondary.you-save {
  position: relative;
  left: 30%;
  bottom: 13px;
} */

strong.or {
  background: #fff;
  padding: 0 10px;
}

.or-text {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #a09e9e;
  line-height: 0.1em;
  margin: -8px 0 16px;
  position: absolute;
  top: 59px;
  left: 0;
}

p.mb-0.cross span {
  text-decoration: line-through;
}

/* .button-position {
  position: relative;
  top: 55px;
} */

.button-colr {
  background-color: var(--theme_color) !important;
  color: #fff !important;
}

.button-colr:hover {
  color: var(--theme_color) !important;
  background-color: #fff !important;
  border: 1px solid var(--theme_color) !important;
}

.badge-cart {
  background-color: red;
  color: white;
}

span.badge-cart {
  position: absolute;
  border-radius: 100%;
  padding: 3px;
}

.badge:empty {
  display: block !important;
}

.plus-item {
  text-align: right;
}

.login-inner input {
  border: 1px solid #ada6a6;
}

.login-form small {
  color: #545351;
}

.error-text {
  font-size: 11px;
  position: relative;
  top: 6px;
}

.bg-grey {
  background: #eef7ff70;
}

.fff-nn {
  bottom: 0;
}

.toggle-div1 {
  padding: 15px 12px 0;
}

li.active-tile a {
  color: var(--theme_color);
  text-align: center;
}

@media screen and (max-width: 768px) {
  body .inbox_msg a {
    position: relative;
    left: 22px;
    top: -5px;
    margin-bottom: 13px;
  }

  body .col-md-10.col-10.toggle-div {
    padding: 9px 32px;
  }

  body .information-action-btn {
    margin-bottom: 57px;
  }

  .list-group-item div {
    font-size: 12px;
  }

  body .outgoing_msg {
    margin: 10px 0 10px;
  }

  body .received_msg {
    padding: 0 0 0px 10px;
  }

  body .received_withd_msg,
  body .sent_msg {
    width: 80%;
  }

  body .cross-sells-section {
    padding: 0;
  }

  .custom-sidenav a span {
    text-align: center;
    display: block;
  }

  .custom-sidenav a {
    font-size: 16px;
    display: table-row;
  }

  .product-address span {
    font-size: 12px;
  }

  body .product-address p {
    font-size: 12px;
    text-align: left;
    font-weight: 400;
  }

  body .product-img h5 {
    font-size: 16px;
  }

  .custom-sidenav a i {
    font-size: 24px !important;
    display: block;
    text-align: center;
    padding-bottom: 6px;
  }

  h6.card-title.visit-appointment {
    padding: 10px 0 20px;
    text-align: center;
  }

  li.active-tile:after {
    display: none !important;
  }

  .heading-title.text-center {
    margin-top: 30% !important;
  }

  .main-sidebar-content h3 {
    margin-top: 75px !important;
  }

  .main-sidebar-content .btn-link {
    padding: 0px 8px 4px !important;
  }

  body .action-btn-stripe .StripeElement {
    margin-top: 2px;
    position: relative;
    top: 22px !important;
    padding: 1px;
    left: 2px;
    right: -2px;
  }

  body .small-text-stripe {
    position: relative !important;
    top: -581px !important;
    text-align: center !important;
  }

  .text-main-success .title {
    font-size: 18px;
    text-align: center;
  }

  .test-information .product-img img {
    width: 70px;
  }

  .text-main-success .work-card-inner {
    flex-direction: column;
  }

  .text-main-success .claim-section.mt-3 {
    text-align: center;
  }
}

@media screen and (max-width: 375px) {
  .custom-sidenav a {
    font-size: 14px;
    text-align: center;
  }

  img.icon-image {
    width: 30px !important;
  }
}

.card.mob-visit-card {
  min-height: 170px;
  height: 100%;
}

ul.site-nav hr {
  margin: 11px 29px;
}

/* .visit-appointment.text-center small {
  text-transform: capitalize;
} */
.result-mob-btn small {
  color: var(--theme_color);
}

li.active-tile:after {
  border-bottom: 3px solid var(--theme_color);
  content: "";
  position: absolute;
  left: 35px;
  display: inline-block;
  width: 100%;
  max-width: 90px;
  top: 0;
  bottom: 4px;
}

img.icon-image {
  width: 24px;
}

button.btn.btn-lg-secondary.btn-view-test.download-test-btn {
  font-size: 85% !important;
  background-color: var(--theme_color) !important;
}

button.btn.btn-lg-secondary.btn-view-test.download-test-btn:hover {
  font-size: 85% !important;
  background-color: #0a4db3c4 !important;
  color: #fff !important;
}

.card.mob-visit-card:hover {
  box-shadow: 0 0.125rem 1.5625rem rgb(0 0 0 / 10%);
}

.product-img img {
  width: 140px;
}

.test-information {
  padding: 12px 0;
  background: aliceblue;
}

.text-main-success.mb-5 {
  max-width: 700px;
  margin: 0 auto;
}

.product-address p {
  font-size: 16px;
  text-align: left;
  font-weight: 400;
}

.product-img {
  text-align: center;
}

.account-info-card {
  box-shadow: 0 0.125rem 1.5625rem rgb(0 0 0 / 10%);
  padding: 20px 20px;
  border-radius: 12px;
  margin-bottom: 30px;
}

.product-address h5 {
  margin-bottom: 0;
}

.claim-section {
  text-align: center;
}

.label-content .form-inner input {
  max-width: 737px;
  width: 100%;
}

.label-content .form-inner .hide-field-input {
  border: none;
  user-select: none;
  pointer-events: none;
  padding: 0;
}

/* Message UI css*/

.inbox_people {
  background: #f8f8f8 none repeat scroll 0 0;
  float: right;
  overflow: hidden;
  width: 40%;
  border-right: 1px solid #e2e2e2;
}

.inbox_msg {
  /* clear: both; */
  overflow: hidden;
  /* border: 1px solid #e2e2e2;
  box-shadow: 0 0.125rem 1.5625rem rgb(0 0 0 / 10%); */
}

.top_spac {
  margin: 20px 0 0;
}

.recent_heading {
  float: left;
  width: 40%;
}

.srch_bar {
  display: inline-block;
  text-align: right;
  width: 60%;
}

.headind_srch {
  padding: 10px 29px 10px 20px;
  overflow: hidden;
  border-bottom: 1px solid #c4c4c4;
}

.recent_heading h4 {
  color: #05728f;
  font-size: 21px;
  margin: auto;
}

.srch_bar input {
  border: 1px solid #cdcdcd;
  border-width: 0 0 1px 0;
  width: 80%;
  padding: 2px 0 4px 6px;
  background: none;
}

.srch_bar .input-group-addon button {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  padding: 0;
  color: #707070;
  font-size: 18px;
}

.srch_bar .input-group-addon {
  margin: 0 0 0 -27px;
}

.chat_ib h5 {
  font-size: 15px;
  color: #464646;
  margin: 0 0 8px 0;
}

.chat_ib h5 span {
  font-size: 13px;
  float: right;
}

.chat_ib p {
  font-size: 14px;
  color: #989898;
  margin: auto;
}

.chat_img {
  float: left;
  width: 11%;
}

.chat_ib {
  float: left;
  padding: 0 0 0 15px;
  width: 88%;
}

.chat_people {
  overflow: hidden;
  clear: both;
}

.chat_list {
  border-bottom: 1px solid #c4c4c4;
  margin: 0;
  padding: 18px 16px 10px;
}

.inbox_chat {
  height: 550px;
  overflow-y: scroll;
}

.active_chat {
  background: #ebebeb;
}

.incoming_msg_img {
  display: inline-block;
  width: 6%;
}

.received_msg {
  display: inline-block;
  padding: 0 0 40px 10px;
  vertical-align: top;
  width: 92%;
}

.received_withd_msg p {
  background: #cdd9e6 none repeat scroll 0 0;
  border-radius: 3px;
  color: #646464;
  font-size: 14px;
  margin: 0;
  padding: 5px 10px 5px 12px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
}

.received_withd_msg p:after {
  content: "";
  position: absolute;
  bottom: -5px;
  border-top: 6px solid #cdd9e6;
  left: 0;
  border-right: 7px solid transparent;
}

.time_date {
  color: #747474;
  display: block;
  font-size: 12px;
  margin-bottom: 11px;
  padding: 0px 10px;
}

.received_withd_msg {
  width: 57%;
}

.mesgs {
  float: right;
  width: 100%;
  /* background-image: url(/portal/chat-bg.webp); */
}

.sent_msg p {
  background: #63a4ff none repeat scroll 0 0;
  border-radius: 3px;
  font-size: 14px;
  margin: 0;
  color: #fff;
  padding: 5px 10px 5px 12px;
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  position: relative;
}

.sent_msg p:before {
  content: "";
  position: absolute;
  border-right: none;
  border-left: 11px solid transparent;
  border-top: 6px solid #63a4ff;
  bottom: -4px;
  right: 0;
}

.outgoing_msg {
  overflow: hidden;
  margin: 26px 0 26px;
}

.sent_msg {
  float: right;
  width: 46%;
}

.input_msg_write input {
  background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
  border: medium none;
  color: #4c4c4c;
  font-size: 15px;
  min-height: 48px;
  width: 85%;
}



.msg_send_btn {
  background: #63a4ff none repeat scroll 0 0;
  border: medium none;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  height: 33px;
  position: absolute;
  right: 20px;
  top: 7px;
  width: 33px;
}

.msg_history::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

.page-content {
  max-width: 1000px;
  margin: auto;
}

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

@media (max-width: 991.98px) {
  .padding {
    padding: 1.5rem;
  }
}

@media (max-width: 767.98px) {

  .padding {
    padding: 1rem;
  }

  .col-md-6.col-12.order-lg-custom-2 {
    order: -1;
  }

  .std-tags-main.mt-5 {
    margin-top: 1.56rem !important;
    margin-bottom: 1rem;
  }
}

.padding {
  padding: 1rem;
}

img {
  max-width: 100%;
}

.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.list-item.block .media {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.list-item.block .list-content {
  padding: 1rem;
}

.w-40 {
  width: 40px !important;
  height: 40px !important;
}

.avatar {
  position: relative;
  line-height: 1;
  border-radius: 500px;
  white-space: nowrap;
  font-weight: 700;
  border-radius: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: center;
  justify-content: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-radius: 500px;
  box-shadow: 0 5px 10px 0 rgba(50, 50, 50, 0.15);
}

.avatar img {
  border-radius: inherit;
  width: 100%;
}

.gd-primary {
  color: #fff;
  border: none;
  background: #448bff linear-gradient(45deg, #448bff, #44e9ff);
}

.flex {
  -webkit-box-flex: 1;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
}

.text-color {
  color: #5e676f;
}

.text-sm {
  font-size: 0.825rem;
}

.h-1x {
  height: 1.25rem;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.no-wrap {
  white-space: nowrap;
}

.list-row .list-item {
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.75rem 0.625rem;
}

.list-item {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
}

.list-row .list-item>* {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.dropdown {
  position: relative;
}

a:focus,
a:hover {
  text-decoration: none;
}

.list-item {
  background: white;
}

body input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.case-title {
  position: absolute;
  right: 30px;
  color: var(--theme_color);
  border-bottom: 2px solid;
}

p.no-data {
  text-align: center;
  margin-top: 203px;
  color: #00000082;
  font-size: 26px;
  font-weight: 100;
}

.beat-loader-portal {
  position: absolute;
  margin: auto;
  display: block;
  top: 50%;
  right: 39%;
}

body span.change-email {
  position: absolute;
  right: 26px;
  font-weight: 500;
  bottom: -15px;
  border-bottom: 2px solid var(--theme_color);
}

button.btn.mt-3.btn-lg-primary.back-profile-btn {
  position: absolute;
  right: 20px;
  top: -59px;
}

a.hide-hours {
  position: absolute;
  left: 52%;
}

body .card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgb(240 248 255);
}

.panel-banner {
  padding: 10px;
  text-align: center;
  background: #2e61acc7;
}

.panel-banner span {
  font-size: 15px;
  color: #fff;
  font-weight: 500;
}

.toogle-main {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  max-width: 459px;
  margin: 0 auto 0 auto;
}

.case-img img {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.receiving-mess-container {
  border: 1px solid #e4e4e4;
  margin-bottom: 10px;
  border-left: 3px solid rgb(77, 171, 247);
  border-radius: 3px;
}

.support-img {
  color: #2157a7;
}

.input_msg_write textarea {
  width: 96%;
  padding: 20px 0;
  border: none;
}

.why-nextmed-box {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.why-nextmed-box .icon img {
  width: 80px;
  height: 80px;
  background: white;
  padding: 10px;
  box-shadow: 0 2px 8px rgb(162 162 162 / 19%), 0 4px 8px rgb(232 232 232 / 0%);
  border-radius: 10px;
}

.why-nextmed-box p {
  padding-left: 20px;
}

.cart-content small {
  color: #767a7d;
}

.tooltip-inner {
  max-width: 460px !important;
}

.btn-tooltip span {
  font-size: 15px !important;
}

.toogle-main span {
  font-size: 13px;
  color: #505050;
}

.input-group-diseases .disease-icon img {
  padding-right: 10px;
  width: 35px;
  position: relative;
  top: -4px;
}

.cart-view-card .input-group-diseases .disease-icon img {
  padding-right: 5px;
  width: 22px;
  top: 0px;
}

.hero-circle-shape {
  position: relative;
}

.hero-circle-shape:before {
  content: "";
  position: absolute;
  left: -30px;
  width: 100px;
  height: 100px;
  background: var(--theme_color);
  bottom: 0;
  border-radius: 50%;
  z-index: -1;
}

.info-tooltip img {
  height: 16px;
  padding: 1px 5px;
  margin-top: -3px;
}

.info-tooltip {
  /* border-left: 1px solid;
  padding: 6px; */
}

img.star-img {
  width: 130px;
  margin: 0px 15px;
  position: relative;
  top: -4px;
}

.choose-loc-radio .option-inner.option-female {
  max-width: 162px;
}

.choose-loc-radio .option-inner {
  margin-right: 0px;
}

/* The container */
.radio-container {
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 17px;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.radio-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.radio-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 50%;
}

/* When the radio button is checked, add a blue background */
.radio-container input:checked~.radio-checkmark {
  border: 2px solid #2196f3;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radio-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radio-container input:checked~.radio-checkmark:after {
  display: block;
}



.subscription-list .radio-container {
  padding-left: 40px;
  font-size: 12px;
  font-weight: 600;
  text-transform: capitalize;
  color: var(--theme_color);
}

.hhh {
  overflow-x: scroll;
  padding: 0 12px;
}

.visit-heading {
  margin: auto;
}

.ins-text {
  color: #878b8e;
}

.ins-true {
  color: var(--theme_color);
  text-decoration: underline;
}

.red-alert {
  border: 1px solid red;
  box-shadow: 0px 0px 4px red;
}

.freez {
  pointer-events: none;
}

.cross-sells-body {
  display: flex;
  width: 100%;
  border: 1px solid #e0e0e0;
  padding: 2px 8px;
  position: relative;
  border-radius: 6px;
}


.cross-sells-section {
  padding: 0 52px;
}

.cross-sells-body button {
  position: absolute;
  right: 13px;
  top: 15px;
}

.cross-sells-section button {
  border-radius: 50px;
  font-size: 13px;
  padding: 1px 13px;
}

h6.cross-title {
  position: absolute;
  top: 5px;
  font-weight: 400;
  color: #533b3b;
  font-size: 14px;
  left: 60px;
}

small.cross-sells-heading {
  display: block;
  text-align: center !important;
}

small.upsells-price-norm1 {
  position: absolute;
  bottom: 5px;
}

.card-main-wrapper {
  overflow-x: auto;
  height: 134px;
}

span.panel-discount {
  text-decoration: line-through;
  font-weight: 400;
  color: #f3ffff;
}

body .set-payment-position {
  position: absolute;
  width: inherit;
  top: 29px;
}

body .more-text {
  margin-top: 111px;
}

body .m-row {
  margin-top: 111px;
}

body .table-result td+td,
.table-result th+th {
  text-align: start;
}

body .table-result th {
  background-color: rgb(230, 231, 220);
}

.doc-team-main {
  padding: 8px 0;
  font-size: 13px;
  display: flex;
}

.doc-team-dec p {
  font-size: 13px;
  font-weight: 200;
  color: #696c6f;
  letter-spacing: 0.008em;
}

.doc-team-dec {
  padding-left: 10px;
}

.doc-team-main .marc-img {
  max-width: 119px;
  width: 100%;
  margin-top: -47px;
  max-height: 116px;
}

span.no_data {
  width: 100%;
  text-align: center;
  display: block;
  font-size: 37px;
  font-weight: 100;
  color: #9c9893;
}

p .existing_user_text {
  color: var(--theme_color);
  font-size: 16px;
  font-weight: 600;
  margin: 5px 0;
  text-decoration: underline;
  cursor: pointer;
}

/* full page loader */
/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.dispay-text {
  text-align: center;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8),
      rgba(0, 0, 0, 0.8));
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.location {
  text-align: start;
  font-size: 14px;
  max-width: 151px !important;
}

.dr-img {
  height: 75px;
  width: 75px;
}

.list-item-pharmacy ul li:hover {
  background: #2157a7;
  color: #fff;
}

.list-item-pharmacy ul {
  padding: 0;
}

.list-item-pharmacy li {
  list-style: none;
  border-bottom: 1px solid #3333;
  padding: 8px 9px;
  cursor: pointer;
}

small.small-text-stripe {
  position: relative;
  top: -488px;
  text-align: center;
}

.custom-charge-modal {
  height: 100%;
  display: flex;
  align-items: center;
}

.text-secondary.password-text {
  text-align: center;
  width: 100%;
  margin: auto;
  display: block;
}

body input.custom-input {
  width: 50% !important;
  margin: 10px auto;
}

.border-div-email {
  border: 1px solid #e0e0e0;
  border-bottom: navajowhite;
  border-top-left-radius: 9px;
  border-top-right-radius: 9px;
}

.klarna-btn button.btn.btn-lg-secondary {
  padding: 10px;
}

button.btn.btn-lg-primary.small-btn-selected {
  font-size: 11px;
  font-weight: 700;
  border-radius: 70px;
  padding: 4px 21px;
  color: #3890ff;
  border: 1px solid #2196f3;
  background: #ffffff;
}

button.btn.btn-lg-primary.small-btn {
  font-size: 11px;
  font-weight: 700;
  border-radius: 70px;
  padding: 4px 21px;
  color: rgb(140, 143, 146) !important;
  background: #fff;
  border: 1px solid #9e9e9e;
}

button.btn.btn-lg-primary.small-btn:hover,
button.btn.btn-lg-primary.small-btn-selected:hover {
  color: #2157a7;
}

p.insurance-text {
  margin: 0;
  margin-bottom: 4px;
}

button.btn.btn-lg-secondary.klarna-btn-payment {
  background: #eb6f93;
}

button.btn.btn-lg-secondary.klarna-btn-payment:hover {
  border: 1px solid #eb6f93;
  color: #eb6f93 !important;
  background: #fff;
}

.radio-container img {
  max-width: 50px;
  /* max-height: 0; */
  position: relative;
  top: -3px;
}

.pay-with-text {
  font-weight: 600;
}

span.span-div {
  display: flex;
  justify-content: center;
  text-align: center;
}

.img-white img {
  height: 19px;
}

.img-white {
  width: 58px;
  height: 14px;
}

.pink {
  display: none;
}

.klarna-btn-payment:hover .pink {
  display: block !important;
  position: relative;
  top: 3px;
  left: 4px;
}

.klarna-btn-payment:hover .white {
  display: none !important;
}

.img-white img {
  height: 100%;
  position: relative;
  top: -2px;
}

span.span-div i {
  position: relative;
  top: 3px;
}

span.span-div {
  font-family: arial;
  font-weight: 900;
}

.t-question {
  position: relative;
  left: 5px;
  top: 1px;
  margin-right: 8px;
}

.btn-apple-content {
  text-align: center;
}

p.msg_ins {
  color: var(--theme_color);
}

p.msg_ins:hover {
  color: black;
}

p.success-span {
  color: #83878a;
  font-size: 15px;
}

p.txt-address {
  text-align: center;
  color: #6f6f6f;
  font-size: 13px;
  margin: 7px;
}

.custom-modal-style {
  width: 897px;
  height: 802px;
}

.fail-password {
  color: #717980;
  font-weight: 400;
  text-align: center !important;
}

p.small-txt {
  font-size: 12px;
  text-align: center;
  color: #615d6d;
}

.msg-content-section {
  margin-left: 225px;
}

.new-retest {
  padding: 0 12px;
  width: 100%;
  flex: 0 0 100%;
}

.new-retest-wrapper {
  display: flex;
  overflow-x: hidden;
  width: 100%;
}

.upsell-head-restest {
  color: var(--theme_color) !important;
  text-align: center !important;
  font-size: 22px;
  font-weight: 500;
}

.restest-button {
  border-radius: 7px !important;
  padding: 5px 7px !important;
}

.inbox_msg a {
  position: relative;
  left: 25px;
}

.msg-content-section1 {
  margin-left: 346px;
}

.prescription-text {
  color: #615d57;
  font-size: 10px !important;
}

.card-main-prescription.text-start {
  padding: 3px 11px;
}

.card-main-prescription img {
  height: 52px;
  width: 52px;
}

.prescrition-btn {
  font-size: 12px !important;
  width: 100%;
}

label.radio-container span {
  font-size: 13px;
  position: absolute;
  padding: auto;
  font-weight: 600;
  margin: 3px;
}

.subscription-card {
  border: 1px solid #d8d2d2;
  padding: 12px;
  margin-bottom: 0px;
  border-radius: 10px;
}

body .card-upsells {
  height: 250px !important;
  position: relative;
}

.button-upsells {
  border-radius: 5px !important;
}

span.original-price1 small {
  position: absolute;
  top: 14px;
  right: 0;
}

.font-weight-bold {
  font-weight: 700;
}

.text-forget {
  font-size: 11px;
}

.dr-imgs {
  max-width: 20%;
  width: 100%;
  flex: 0 0 20%;
}

.doc-team-main img {
  height: 100px;
  border: 2px solid #6bb6fb;
  width: 100px;
}

p.insurance-txt {
  font-size: 16px;
  font-weight: 600;
  margin-top: 9px;
  margin-bottom: 0;
}

img.document-img {
  width: 30%;
}

.consent-val {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}

input#vehicle1 {
  font-size: 65px;
  width: 45px;
  height: 20px;
  position: absolute;
}

p.bmi-text {
  color: #4697ff;
  font-size: 16px;
  text-align: center;
}

.receiving-mess-container.by-you {
  border-left: 3px solid #81e019;
}

span.doc-your-name {
  font-size: 14px;
  font-weight: 600;
  margin: 5px;
}

span.from-you {
  font-weight: 600;
}

.bg-color-restest {
  background: #e7f7fa !important;
}

span.no-messages {
  font-size: 29px;
  font-weight: 100;
  position: relative;
  top: 118px;
  color: #92887d;
}

.messages-heading {
  position: absolute;
  left: 55%;
}

.prescription-detail-card {
  border-left: #f8cf04 3px solid !important;
}

.prescription-card-main {
  display: grid;
}

.card-prescriptions-text {
  font-size: 13px;
  font-weight: 400;
  color: #544d4d;
}

/* radio .btn */
.custom-radio-check {
  padding: 3px 0;
  text-align: center;
}

.tile-color {
  border: 1px solid #d6d6d6;
  background: aliceblue;
  color: var(--theme_color);
  font-weight: 400;
}

.stack-top {
  z-index: 9;
}

.subscription-payment-wrraper {
  background: #f0f8ffe6;
  text-align: center;
  margin: 10px 0;
  padding: 20px 0px;
  width: 100%;
  position: fixed;
  top: 0;
  height: 100%;
  z-index: 9999999;
}

.active-subscription {
  background: aliceblue;
  width: 100%;
  padding: 15px 3px;
}

.deactive-subscription {
  padding: 15px 3px;
}

.you-save {
  font-size: 12px;
}

p.account_text1 {
  position: relative;
  top: -12px;
}

.shipping-address1 {
  margin: 0;
}

span.information-heading1 {
  font-size: 20px !important;
  position: relative !important;
  top: -15px !important;
}

span.f-p1 {
  position: relative;
  top: -15px !important;
}

.card.custom-card {
  box-shadow: rgb(255 255 255 / 10%) 0px 1px 1px 0px inset,
    rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  border: none;
}

.account-info-card-subscription {
  box-shadow: 0 0.125rem 1.5625rem rgb(0 0 0 / 10%);
  padding: 20px 20px;
  border-radius: 12px;
  margin-bottom: 60px;
  overflow-x: auto;
}

.card.feedback-card.mt-5 {
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px;
  border: navajowhite;
}

.feedback-logo img {
  width: 193px;
  justify-content: center;
  display: flex;
  text-align: center;
  margin: auto;
}

.feedback-heading span {
  font-size: 18px;
}

.feedback-heading small {
  font-size: 13px;
  color: #838384;
}

.feedback-field div {
  display: inline-block;
}

.select2-selection {
  width: 31%;
  margin: auto;
}

.feedback-field i {
  font-size: 18px;
}

.feedback-field i:hover {
  font-size: 18px;
  color: var(--theme_color);
}

.custom-color {
  color: var(--theme_color);
}

.tooltip-custom {
  position: relative;
}

.tooltip-custom .tooltiptext {
  visibility: hidden;
  width: auto;
  background-color: var(--theme_color);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 12px;
  position: absolute;
  z-index: 1;
  bottom: 80%;
  left: 50%;
  margin-left: -18px;
}

.modal-header span {
  color: #9e9e9e !important;
}

.tooltip-custom .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: var(--theme_color) transparent transparent transparent;
}

.tooltip-custom:hover .tooltiptext {
  visibility: visible;
}

.heading-part-product p {
  margin-bottom: 0;
}

.background-svg::before {
  content: "";
  background-image: url(../public/images/quiz.webp);
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  background-size: 40%;
  /* background: var(--theme_color)57 !important; */
  z-index: -1;
}

.background-svg {
  position: relative;
  height: calc(100vh - 50px);
}

.kids-card {
  padding: 60px;
  margin: 28px 0;
  font-size: 17px;
  font-weight: 200;
  height: 220px;
  border: 1px solid black !important;
  cursor: pointer;
}

.kids-card:hover {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

body .kids-card-choice {
  padding: 20px;
  margin: 28px 0;
  font-size: 17px;
  font-weight: 200;
  border: 1px solid black !important;
  cursor: pointer;
}

.kids-card-choice:hover {
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
}

span.x-factor {
  float: right;
}

.head-kid-wrapper h3 {
  text-align: center;
  font-weight: 400;
}

.head-kid-wrapper {
  text-align: left;
}

.back-div span {
  font-size: 18px;
  color: black;
  cursor: pointer;
}

.add-ico i {
  font-size: 18px;
  color: var(--theme_color);
}

.home-appoit1 p {
  color: #4b9bff;
  font-size: 16px;
  font-weight: 600;
}



small.prescription-text1 {
  font-size: 13px;
  text-align: justify;
  color: #5c5656;
  font-weight: 400;
}

/* span.prescription-text1 {
  position: relative;
  top: 4px;
} */
body .t-question1 {
  position: relative;
  left: 5px;
  top: 0px;
  font-size: 14px;
  color: var(--theme_color) !important;
}

body .fotter-list-img.text-center.mt-5 a {
  font-weight: 400;
}

.feedback-thanks img {
  height: 50px;
}

img.google-img {
  position: relative;
  left: 6px;
}

.feedback-thanks span {
  font-size: 22px;
  font-weight: 300;
  letter-spacing: 1px;
}

.thanks-body {
  border: none;
  box-shadow: rgb(50 50 93 / 25%) 0px 50px 100px -20px,
    rgb(0 0 0 / 30%) 0px 30px 60px -30px;
}

.backgroud-color:before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  background: var(--theme_color) 57;
  height: 100%;
  width: 100%;
  z-index: -1;
  top: 0;
}

body .card-active {
  background: var(--theme_color) 57;
}

.get-info-btn {
  background: black !important;
}

body .get-info-btn:hover {
  background: white !important;
  border: 2px solid black !important;
  color: black !important;
}

body .age-info-quize {
  border-radius: 0px;
  border: 1px solid;
  height: 50px;
}

body .age-info-quize :focus {
  border-radius: 0px;
  border: 1px solid;
  height: 50px;
}

.stripped-table {
  overflow-x: scroll;
}

.stripped-btn {
  font-size: 12px !important;
  padding: 8px 6px !important;
}

img.herpes-img {
  height: 68px;
}

.std-product {
  position: relative;
  min-height: 520px;
}

.carosuel-product {
  position: absolute;
  bottom: 20px;
  left: -70px;
  display: flex;
  flex-direction: column;
}

.product-img {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 3px solid #2157a7;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin-bottom: 12px;
}

.product-img1 {
  width: 60px;
  height: 60px;
  cursor: pointer;
  border: 2px solid black;
  border-radius: 50%;
  object-fit: cover;
  display: block;
  margin-bottom: 12px;
}

.quantity_main {
  border: 1px solid #bdbcbc;
  border-radius: 3px;
  margin: 20px 0;
  width: 100%;
  max-width: 450px;
}

.quantity-wrapper {
  display: flex;
  padding: 10px 10px;
  border-bottom: 1px solid #bdbcbc;
  position: relative;
}

.quantity-wrapper:last-child {
  border-bottom: none;
}

.quantity-wrapper .input-content {
  padding-left: 10px;
  font-size: 15px;
  font-weight: 500;
}

.quantity-wrapper .input-content p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0;
}

.quantity-wrapper.active-product {
  background: var(--theme_color) 2e;
}

.quantity-wrapper.active-product .input-content p,
.quantity-wrapper.active-product .input-content {
  color: var(--theme_color);
}

.quantity-wrapper input.product-radio {
  height: 34px;
  width: 18px;
  margin-top: -4px;
}

.heading-part-product {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

p.cross-text {
  font-size: 15px;
  text-decoration-line: line-through;
  color: #000;
  line-height: 0;
  margin-bottom: 0;
  text-align: right;
  font-style: italic;
  padding-bottom: 20px;
}

.heading-part-product .text-end {
  color: var(--theme_color);
  font-size: 20px;
}

.product_detail_main {
  padding-left: 40px;
}

.product-btn {
  width: 100%;
  max-width: 450px;
  text-decoration: underline;
}

.product_detail_main {
  margin-left: 50px;
}

.card-label-1 {
  border: 2px solid #4c99dc;
  width: 100%;
  max-width: 140px;
  background: #4c99dc;
  color: #fff;
  text-align: center;
  position: absolute;
  border-radius: 3px;
  font-size: 13px;
  bottom: 0px;
  right: 0px;
}

small.product-small-text {
  font-size: 13px;
  font-weight: 400;
  font-style: italic !important;
}

strong.cross-text-product {
  text-decoration: line-through;
}

.free-shipping-img img {
  height: 60px;
  width: 60px;
  border: 2px solid #72b2df;
  border-radius: 50%;
  object-fit: cover;
}


span.radio-checkmark {
  margin: 0 !important;
}

.chooseLocation-tbtn1 {
  width: 30%;
}

.row.border-div2 {
  margin: 0;
  border-radius: 4px;
  margin-top: 10px;
}

.form-inner-1 input {
  width: 100%;
}

.checkout-card.active-tab1 {
  background: aliceblue;
}

@media (min-width: 398px) and (max-width: 767px) {
  span.form-label.insurance1 {
    top: 14px;
  }
}

.lg-form-control1 {
  border: 1px solid #999999;
  padding: 8px 12px;
  font-weight: 400;
  width: 100%;
  color: #1f1d1d;
  border-radius: 4px;
}

/* .membership-section{
  background-color: #f7f8fa;
} */
.membership-list ul li {
  list-style-type: none;
  padding: 0;
  font-size: 17px;
  font-weight: 400;
}

.tickicon {
  color: #368fff;
  font-size: 20px;
}

li.gapp {
  margin-top: 14px;
}

.membership-thin {
  font-size: 20px !important;
}

.membership_plan {
  display: flex;
  gap: 14px;
  align-items: baseline;
}

.membership-card {
  padding: 20px 25px 25px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 0;
}

.card.membership-card {
  border: none;
}

span.card-title-membership {
  font-size: 20px;
  font-weight: 600;
}

.select-plan::before {
  content: "";
  background: transparent;
  border-radius: 50%;
  border: 0.75px solid rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-position: 50%;
  background-repeat: no-repeat;
  display: inline-block;
  width: 25px;
  height: 25px;
}

.selected-membership {
  outline: 2px solid #1862ff;
  border-radius: 8px !important;
}

.select-val {
  border: 2px solid #1862ff !important;
  border-radius: 8px !important;
}

.membership-list ul {
  padding: 0;
}

.membership-banner {
  background: #f8f8f8;
}

.membership-banner-content {
  gap: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 25px;
}

p.membership-name {
  font-size: 18px;
  font-weight: 500;
}

.premium-member {
  background-image: linear-gradient(315deg, #63a4ff 0%, #83eaf1 74%);
  padding: 0;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  font-size: 20px;
  color: white;
  font-weight: 600;
  border-radius: 0.25rem;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  height: 30px;
}

h4.cart-heading.price.member-pp {
  position: absolute;
  right: 0;
  top: -4px;
  color: #388fff;
  font-size: 12px;
}

p.membership-subtitle {
  font-style: italic;
  margin-bottom: 0;
  font-weight: 500;
}

.membership-section {
  background: aliceblue;
}

ul.testnames-list {
  list-style-type: none;
  text-align: start;
  margin-bottom: 0;
}

h2.cart-heading-membership {
  font-size: 14px;
}

.main-height {
  margin-bottom: 20px;
}

body .insurance-t-btn {
  font-size: 15px;
  font-weight: 700;
  border-radius: 70px;
  padding: 4px 34px;
  color: rgb(140, 143, 146) !important;
  background: #fff;
  border: 1px solid #e6e6e6;
}

body .insurance-t-btn-selected {
  font-size: 15px;
  font-weight: 700;
  border-radius: 70px;
  padding: 4px 34px;
  color: #3890ff;
  background: #fff;
  border: 1px solid #3890ff;
}

body .treatment-btn {
  font-size: 15px;
  font-weight: 700;
  border-radius: 70px;
  padding: 4px 34px;
  color: rgb(140, 143, 146) !important;
  background: #fff;
  border: 1px solid #e6e6e6;
}

body .treatment-btn-selected {
  font-size: 15px;
  font-weight: 700;
  border-radius: 70px;
  padding: 4px 34px;
  color: #3890ff;
  background: #fff;
  border: 1px solid #e6e6e6;
}

label.card-label-3 {
  border: 2px solid #4c99dc;
  width: 30%;
  background: #4c99dc;
  color: #fff;
  text-align: center;
  position: absolute;
  top: -12px;
  border-radius: 6px;
  left: 30px;
  font-size: 10px;
}

.row.mt-2.d-flex.main-mmbership {
  justify-content: space-between;
}

small.encroll-nextmed {
  font-weight: 500;
  color: #3d93ff;
}

span.dki-color {
  font-size: 30px;
  /* color: #766868; */
  font-weight: inherit;
  color: var(--theme_color);
}

.home-logos-images {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

@media screen and (max-width: 768px) {}

.home-logos-images img {
  width: 100%;
  max-width: 96px;
}

.home-logo-main {
  background: aliceblue;
}

small.no-subscrioption-text {
  color: #368fff;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
}

span.home-as-seen {
  font-size: 20px;
  font-weight: 600;
  color: #828689;
}

.std-main1 {
  position: relative;
}

small.text-mute.std-main-subtitle {
  font-size: 17px;
  color: #3d3f41;
}

.slider_inner {
  display: flex;
  margin: 0 20px;
  flex-direction: column;
  padding: 20px;
  background-color: #ffffff;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.slide_content {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.slide_content img {
  width: 18px;
  height: 18px;
  margin-right: 6px;
}

.slider_p {
  display: flex;
  gap: 12px !important;
}

.personimg img {
  width: 99px;
  height: 67px;
  border-radius: 50%;
  border: 1px solid;
  object-fit: cover;
}

.slider_content_inner p {
  margin-bottom: 0 !important;
}

.Slider_badges {
  gap: 4px;
  display: flex;
}

.Slider_badges .badge1 {
  color: #e60000;
  background-color: antiquewhite;
}

.Slider_badges .badge2 {
  color: blue;
  background-color: antiquewhite;
}

.seetimes {
  display: flex;
  justify-content: space-between;
  background-color: azure;
  align-items: center;
  padding: 12px 10px;
}

.seetimes p {
  margin-bottom: 0px !important;
}

.btn-div {
  margin-top: 20px;
  text-align: center;
}

.slider_p p {
  font-size: 12px;
}

.select-test-std {
  width: 80%;
}

.partner-section-list ul,
.partner-section-list-1 ul {
  list-style: none;
}

.main-footer .form-inner input {
  margin: 0;
}

.main-footer .form-inner {
  display: flex;
}

.main-footer button {
  border-radius: 0;
}

span.information-heading {
  color: black !important;
  font-weight: 500 !important;
}

.city-section-home-tile {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: #368fff;
  /* border-top: 1px solid; */
  border-bottom: 1px solid;
}

.section-tile {
  color: #368fff;
  border-top: 1px solid;
}

.city-section-content span {
  color: black;
  font-weight: 500;
}

.btn-intake-change {
  display: flex;
  justify-content: space-evenly;
}

.intake-stepper {
  display: flex;
  justify-content: center;
  margin-bottom: 5%;
  gap: 7px;
}

.intake-stepper span {
  font-size: 16px;
  font-weight: 600;
}

span.head-sp {
  color: #2b6fff;
  font-weight: 500;
}

.div-test {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.div-test h4 {
  color: black;
  font-size: 18px;
}

.card-w-text p {
  position: relative;
  top: 9px;
}

.card-w-text {
  position: absolute;
  /* border: 1px solid; */
  padding: 0 16px 2px 13px;
  font-size: 11px;
  background: aliceblue;
  font-weight: 600;
  bottom: -1%;
  color: #3084e6;
  border-radius: 2px;
  left: -1px;
  display: flex;
  justify-content: flex-end;
}

.panel-price h3 {
  font-size: 17px;
}

.panel-price {
  display: flex;
  justify-content: space-between;
}

.refund-intake-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-auth-div {
  display: flex;
  justify-content: center;
  /* align-items: end; */
  gap: 7px;
}

span.google-img-auth img {
  width: 21px;
  position: relative;
  top: -1px;
}

.benefit-main-wrapper {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.benefit-main-wrapper img {
  width: 10%;
}

.benefit-main-wrapper b {
  font-size: 13px;
}

.medicine-img-wrapper {
  width: 38px;
  text-align: center !important;
  margin-bottom: 12px;
}

.confirm-modal-div {
  display: flex;
  justify-content: space-evenly;
}

button.btn.btn-lg-success.brown-btn.btn-view-test {
  background: gray;
}

label.radio-container.one-time-test {
  color: grey;
}

.panel-banner.weight-loss-banner {
  background: #368fff !important;
}

.panel-banner.weight-loss-banner span {
  font-size: 15px !important;
}

.testimonial-section-partner {
  background: #f0f8ff;
}

.partner-section-list {
  display: flex;
  justify-content: center;
}

.pricing_programs1 {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cost h2 {
  color: white;
  font-size: 25px;
  font-weight: 900;
}

.cost p {
  color: white;
  width: 80%;
  text-align: center;
  margin: auto;
}

.weight-loss-check-btn {
  font-size: 19px !important;
}

.divder-div {
  border: 1px solid #ebe8e8;
  border-radius: 9px;
  margin-bottom: 20px;
}

.saparate-section {
  margin-top: 15%;
}

.plc-btn-new {
  margin-top: -13px !important;
}

.border-div23 {
  border-bottom: 1px solid #e6e6e6;
  margin: 0;
}


.form-control.pt-2.new-field-checkout {
  border-bottom-left-radius: 9px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 9px;
  border-color: #d7d7d7;
}

.section-gap1 {
  padding: 22px 0 !important;
}

.plc-btn-new1 {
  margin-top: -15px;
}

.header-div-pricing {
  display: flex;
  justify-content: space-between;
}

.plc-btn-new2 {
  margin-top: -44px;
}

span.allergy-card-test-name.d-block {
  min-width: 17px;
}

.cart-img {
  display: flex;
  align-items: center;
}

.section-gap-acne-work {
  padding: 58px 0;
}

.pricing_programs1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 26px;
}

.first_price1 span {
  font-size: 13px;
  color: #fff;
  text-align: center;
}

.first_price1 {
  text-align: center;
  width: 22%;
}

.support-img {
  display: flex;
  justify-content: center;
  align-items: center;
}

.call-heading-support {
  filter: grayscale(1);
  cursor: pointer;
  padding: 9px 0;
}

span.span-heading-support {
  font-weight: 600;
}

.call-heading-support:hover {
  filter: grayscale(0);
  color: var(--theme_color);
}

.hrrr {
  color: gray;
}

.my-testi {
  background: aliceblue;
}

.insure-btn-treatment {
  display: flex;
  gap: 18px;
}

@media screen and (max-width: 768px) {
  .col-md-12.col-12.toggle-div {
    padding: 0;
  }

  body .insure-btn-treatment {
    display: grid !important;
    grid-gap: 18px !important;
    gap: 18px !important;
  }

  .new-1 {
    order: 1 !important;
    margin: -14px !important;
  }

  .new-2 {
    order: 0 !important;
  }
}

.weight-loss-ba {
  background: #f0f8ff;
}

.htw_testimonial .weight-loss-ba {
  background: #05728f;
}

.pricing_programs h2 {
  color: #fff;
  text-align: center;
  font-weight: 600;
  font-size: 27px;
}

.cost-main-price p {
  color: white;
  text-align: center;
  width: 100%;
}

.pricing_programs-cost {
  display: flex;
  justify-content: center;
  align-items: center;
}

.cost-main-price h2 {
  color: #fff;
  text-align: center;
  font-weight: 700;
  font-size: 27px;
}

@media screen and (max-width: 767px) {
  .faq_banner {
    height: 283px;
  }

  .aknoe {
    position: relative;
    left: 42px !important;
    top: 15px;
  }
}

.aknoe {
  position: relative;
  left: 54px;
  top: 15px;
}

.promo_code {
  font-size: 15px;
}

.promo-order-wrapper,
.promo-order-wrapper a {
  color: #198754;
  font-style: italic;
  font-size: 14px;
}

/* css */
.card.weightloss-membership-wrapper {
  border: none;
  box-shadow: 0 0 6px 0 #00000052;
  border-radius: 9px;
  text-align: center;
  padding: 20px;
}

.card.weightloss-membership-wrapper h5 {
  color: #000;
  font-size: 11px;
  margin: 0;
  text-transform: uppercase;
}

.card.weightloss-membership-wrapper h5 span {
  font-weight: 400;
  font-size: 11px;
}

h6.card-subtitle.mb-2.text-muted {
  margin-top: 20px;
  font-size: 25px;
  margin-bottom: 0 !important;
  font-weight: 600;
  color: #000 !important;
}

.card.weightloss-membership-wrapper smalll.text-muted.small {
  display: block;
  font-size: 13px;
  color: #3333;
}

smalll.small-info-pay {
  display: block;
  font-size: 11px;
  margin-top: 17px;
  color: #6c757d !important;
}

span.save-color {
  color: #ea0303;
  display: block;
  margin: 2px 0 21px;
  font-size: 10px;
  min-height: 16px;
}

button.btn.btn-lg-primary.weightloss-member-btn {
  background: #368fff;
  color: #fff;
  border: 1px solid #368fff;
  padding: 7px 41px;
  border-radius: 3px;
  font-size: 10px;
  text-transform: uppercase;
}

button.btn.btn-lg-primary.weightloss-member-btn:hover {
  background: #fff;
  color: #368fff !important;
}

.card.weightloss-membership-wrapper {
  margin: 50px 0;
  position: relative;
  overflow: hidden;
}

.absolute_col {
  background-image: url(../public/weight/cross.webp);
  padding: 19px 0;
  position: absolute;
  right: 0;
  top: 0;
  background-size: 100% 100%;
  width: 30%;
}

.absolute_col p {
  transform: rotate(44deg);
  position: relative;
  left: 20%;
  bottom: 6px;
}

.absolute_col span {
  display: block;
  font-size: 8px;
  color: #fff;
  line-height: 11px;
  text-transform: uppercase;
}

.highlight-error {
  border: 1px solid red;
  color: red !important;
  box-shadow: 0px 0px 5px 1px red;
}

.card.pharmacy-card .card-body {
  padding: 8px;
  /* border: 1px
px
saddlebrown; */
}

a.return-to-home.pharmacy-a {
  position: relative;
  bottom: 0;
  right: 0;
  text-align: right;
  width: 100%;
  border: none;
}

.form-inner-val {
  width: 100%;
}

.feedback-input {
  width: 30%;
}

.member-active {
  background: aliceblue !important;
}

.add-border {
  border-bottom: 1px solid #d9d5d5;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.affirm-testprice p {
  font-size: 13px;
}

.affirm-testprice1 {
  display: block;
  min-height: 56px;
}

p.affirm-as-low-as1 {
  display: inline-block;
}

p.affirm-as-low-as1 img {
  width: 35%;
}

.place-order-btn .btn.btn-lg-secondary img {
  display: inline-block;
  width: 50px;
  position: relative;
  bottom: 7px;
  left: 4px;
}

.affirm-payment img {
  width: 39%;
  margin: 5px 0;
}

.affirm-btn {
  background: #fff !important;
  border: 2px solid #5f5feb !important;
  color: black !important;
  font-weight: 700 !important;
  padding-top: 21px !important;
  cursor: pointer !important;
}

body .affirm-btn:hover {
  color: black !important;
}

.affirm-payment {
  min-height: 28px;
}

span.power-text {
  font-size: 10px;
  min-height: 18px;
  text-transform: uppercase;
  /* background: black; */
  display: block;
}

body .search-location input {
  border-radius: 26px !important;
}

span.form-label1 {
  font-size: 18px;
  font-weight: 500;
}

.btn-subcriiption-wrapper {
  display: flex;
  justify-content: space-between;
}

.btn-subcriiption-wrapper-1 {
  display: grid;
  gap: 14px;
}

.btn-subcriiption-wrapper-1 button {
  font-size: 13px !important;
}

.space-text {
  min-height: 39px !important;
}

img.upfront-img {
  width: 36px;
  position: relative;
  top: -5px;
  left: 3px;
}

.affirm-btn {
  font-size: 13px !important;
  padding: 5px !important;
  padding-top: 9px !important;
}

.main-card-container {
  padding: 7% 14%;
}

.email-form-control {
  padding: 11px !important;
}

p.title b {
  font-size: 31px;
  font-weight: 400;
}

.action-plus-btn .continue-plus-checkout {
  padding-left: 0px;
}

@media screen and (min-width: 650px) {
  .action-plus-btn {
    width: 650px !important;
    justify-content: center;
  }
}

/* contact us _page */
.contact_container .contact_us_heading {
  margin-top: 2rem;
}

.contact_container .contact_address {
  display: flex;
  gap: 6px;
}

.contact_container .contact_address .fa-globe:before {
  color: #368fff;
}

.contact_container .contact_address .address,
.contact_container .col-4 .phone_no .phone_no_inner {
  display: flex;
  flex-direction: column;
}

.contact_container .contact_address .address p {
  margin-bottom: 6px !important;
}

.contact_container .contact_address .address .email {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.contact_container .needhelp_heading {
  margin-bottom: 1rem;
}

.contact_container .col-4 .phone_no p {
  margin-bottom: 6px !important;
}

.contact_container .col-4 .phone_no {
  display: flex;
  gap: 6px;
}

.contact_container {
  margin-bottom: 3rem;
}

.contact_container select#dm_subject {
  width: 100%;
}

.contact_container label.control-label {
  padding: 10px 0;
}

p.membership_text_color {
  padding-top: 20px;
  padding-bottom: 0;
  color: var(--theme_color) !important;
  text-align: center;
}

p.membership_text_color :hover {
  color: var(--theme_color) !important;
}

/* ----> visit popup  */
.custom-modal-style-member.modal-content {
  height: 450px;
  width: 850px;
}

.custom-modal-style-member .modal-content {
  height: auto;
  flex-direction: column !important;
  padding-right: 0;
  align-items:  flex-end;
}

.custom-modal-style-member .modal-content .header_member.modal-header {
  border: navajowhite;
}

.custom-modal-style-member .modal-content .modal-body {
  padding: 15px !important;
}

.custom-modal-style-member .modal-content p {
  color: var(--theme_color);
}

/* popup */
.modal1 {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
}

/* @media (min-width: 576px) {
  .modal-dialog {
    max-width: 100% !important;
    margin: 0 !important;
  }
} */

.cookies_btn {
  padding: 7px 32px !important;
  font-size: 18px !important;
  font-weight: 700 !important;
  border-radius: 5px !important;
}

.member_body.modal-body {
  width: 98%;
}

p.member_p {
  font-size: 16px;
}

p.member_p2 {
  font-size: 16px;
  color: gray !important;
}

.header_member.modal-header {
  align-items: baseline;
  align-items: baseline;
}

/* --------------> visit popup */

.panel-btn-wrapper {
  display: flex;
  justify-content: space-between;
  color: #4396ff;
}

.panel-btn-wrapper a {
  color: #4396ff;
}

.card.home-tile-card ul {
  font-size: 13px;
  color: #5f666e;
}

.home-tile-card img {
  max-width: 42px;
  position: absolute;
  left: -26px;
  top: 7px;
  background-color: #fff;
  border-radius: 50%;
}

.home-tile-card {
  box-shadow: rgb(100 100 111 / 20%) 0px 7px 29px 0px;
  border: 0 !important;
  border-radius: 7px !important;
}

.card.home-tile-card h5 {
  font-size: 18px;
}

.row.view-test-main-row .col-md-4 {
  margin-bottom: 20px;
}

@media (min-width: 1152px) and (max-width: 1300px) {
  .row.view-test-main-row .col-md-4 .card.home-tile-card {
    height: 280px;
  }
}

.row.view-test-main-row .col-md-4 .card.home-tile-card {
  height: 220px;
}

@media screen and (max-width: 768px) {
  .new-triger.mt-3 {
    width: 89% !important;
    text-align: justify !important;
  }

  .panel-btn-wrapper a {
    font-size: 13px !important;
  }

  .row.view-test-main-row {
    padding-left: 15px;
    padding-right: 20px;
  }

  .row.view-test-main-row .col-md-4 .card.home-tile-card {
    height: 100%;
  }
}

.row.view-test-main-row .col-md-4 .card.home-tile-card {
  margin: 10px;
}

.new-triger.mt-3 {
  width: 94%;
  text-align: justify;
}

/* StdTesting Table ------>*/
.table-content-cost table {
  width: 100%;
}

.table-content-cost tr {
  border-bottom: 1px solid #999;
  display: flex;
  align-items: center;
}

.table-content-cost th,
.table-content-cost td {
  border: 0;
  padding-left: 0;
  font-size: 13px;
  min-width: 25%;
  display: flex;
  flex: 1 1 0;
  padding: 20px 12px 19px 16px;

  word-break: break-word;
}

@media screen and (min-width: 768px) {

  .table-content-cost th,
  .table-content-cost td {
    font-size: 18px;
  }
  .beat-loader-portal {
    right: 48%;
  }
}

.table-content-cost th {
  border: 1px solid #666;
  /* padding: 0.5em; */
  text-align: left;
  vertical-align: top;
}

.button.btn.btn-primary {
  padding: 14px 20px 16px;
}

.cost_btn {
  width: 100%;
  max-width: 310px;
  background: var(--theme_color) !important;
}

@media screen and (max-width: 425px) {
  .cost_btn {
    width: 100%;
    max-width: 100%;
    background: var(--theme_color) !important;
  }
}

.table-content-cost tr {
  border-bottom: 1px solid #000;
}

@media screen and (min-width: 768px) {
  .table-content-cost table {
    width: 66.67% !important;
  }
}

.circle {
  position: relative;
}

/* .circle:after {
  content: "";
  background: url(../images/circle.webp) no-repeat center center/100% 100%;
  left: -17px;
  position: absolute;
  right: -25px;
  top: -5px;
  bottom: -5px;
}
span.circle {
  margin-left: 12px;
} */
.container.Whatitcosts {
  font-family: jost !important;
}

.container.Whatitcosts {
  padding-bottom: 20px;
}

td.td_cost {
  font-weight: 500;
}

span.circle {
  border-bottom: 2px solid;
}

.table-header-content-cost h2 {
  margin-bottom: 18px;
}

.container.Whatitcosts {
  padding-top: 5px;
}

/* <--------------StdTesting Table */
.home-logos-images-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.span-test {
  font-size: 15px;
  font-weight: 600;
  text-align: center !important;
  justify-content: center;
  align-items: center;
  margin-top: 21px;
  color: #5b708a;
}

.span-test img {
  width: 60px;
  position: relative;

  top: -7px;
}

.weight-loss-cart {
  height: 228px !important;
}

h5.home-as-seen-1 {
  font-size: 20px;
  color: gray;
  font-weight: 600;
}

.async-hide {
  opacity: 0 !important;
}

span.panel-text {
  font-size: 15px;
  font-weight: 600;
  color: #5b708a;
}

sup {
  top: -0.8em;
  font-size: 9px;
}

table.cart-table i {
  margin-right: 11px;
}

.new-color {
  color: #5b708a;
}

.std-points {
  height: 262px !important;
  margin-right: 11px;
}

@media screen and (max-width: 768px) {
  .std-points {
    height: auto !important;
    margin-right: 11px;
  }
}

/* --------------> .our-mission */
.our-mission-video {
  padding-left: 32px;
  padding-bottom: 30px;
}

@media screen and (max-width: 768px) {
  iframe.iframe-our-mission {
    width: 100%;
    height: 100%;
  }

  .our-mission-video {
    padding-left: 0;
    padding-bottom: 30px;
  }
}

/* <------------ .our-mission */

/* ------------->Knowledge */
.blog-banner {
  background-color: #1b1e22;
  padding: 60px 50px 90px 50px;
}

h2.h2-blog-h2 {
  color: white;
  font-size: 30px;
  font-weight: 700;
  margin-top: 20px;
}

.small-text.article-meta-date {
  color: white !important;
  opacity: 0.5;
  font-size: 19px;
}

.small-para-blog {
  color: white;
  margin: 30px 0 50px;
  font-size: 22px;
}

.latestStory {
  margin: 93px 0 50px;
}

.col-6.blog_social {
  display: flex;
  font-size: 20px;
  margin-top: 37px;
  justify-content:  flex-end;
}

button.btn.btn-primary.blog-btn {
  background-color: #368fff;
  padding: 16px 40px 16px 40px;
  font-size: 20px;
  border-radius: 7px;
}

.blog_social .fa .fa-facebook-official {
  font-size: 27px;
}

.blog-banner {
  border-radius: 14px;
}

.container {
  /* padding-bottom: 50px; */
  padding-bottom: 0;
}

.article-meta {
  display: flex;
  gap: 15px;
}

h6.text-primary-2 {
  margin-top: 4px;
}

.col-md-6.livingADHD {
  padding-top: 100px;
  padding-left: 46px;
}

img.image-banner-blog {
  border-radius: 14px;
}

h6.text-primary-2 {
  color: #ff7d00;
  font-size: 20px;
}

h1.knowledge-heading {
  font-size: 55px;
  font-weight: 600;
}

img.blog-img {
  /* border-radius: 14px; */
}




.blog-wrapper .row .col-md-4 {
  padding: 10px;
}

p.blog-p {
  font-size: 22px;
  color: #000;
  -webkit-line-clamp: 4;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  font-size: 17px;
}

.blog-author-slug {
  font-size: 18px;
  font-weight: 500;
}

h4.blog-h4 {
  font-size: 30px;
  padding-top: 10px;
  cursor: pointer;
  font-weight: 300;
  color: #368fff;
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.btn-primary:hover {
  color: #fff;
  background-color: #368fff;
  border-color: #368fff;
}

.tab-menu-blog {
  display: flex;
  gap: 20px;
}

button.btn.btn-primary.Banner-btn {
  padding: 18px 20px 18px 20px;
  border-radius: 7px;
}

.large-text {
  font-size: 24px;
}

.fa-fa-blog-icon {
  font-size: 50px;
  position: absolute;
  right: 20px;
  overflow: hidden;
  margin-top: -70px;
}

h1.sub-heading {
  font-size: 65px;
  font-weight: 700;
  width: 50%;
}

span.sub-heading2 {
  font-size: 65px;
  font-weight: 700;
  color: #484c51;
  padding-bottom: 15px;
}

form.row.sub {
  padding-bottom: 35px;
}

input#email-1 {
  padding: 17px 100px 17px 100px;
  border-radius: 7px;
  text-align: center;
}

input#inputName {
  padding: 17px 100px 17px 100px;
  border-radius: 7px;
  text-align: center;
}

button.btn.btn-primary.mb-3.sub {
  padding: 17px 55px 17px 55px;
  border-radius: 7px;
}

p.info-sub {
  font-size: 22px;
}


.stay-updated {
  width: 70%;
}

.blog-wrapper .row {
  --bs-gutter-x: 70px;
}




.blog-interested h6 {
  display: flex;
  justify-content: center;
}

img.slug-img {
  width: 1155px;
  height: 400px;
  object-fit: cover;
  border-radius: 14px;
}

img.slug-img-blog {
  width: 1155px;
  height: 400px;
  object-fit: cover;
  /* border-radius: 14px; */
}

.card-slug-content {
  padding-top: 30px;
}

.container-large {
  display: flex;
  gap: 31px;
}

button.btn.btn-primary.blog-btn-mobile {
  background-color: #368fff;
  padding: 12px 34px 12px 34px;
  font-size: 20px;
  border-radius: 7px;
  width: 100%;
}

.blog-tabs {
  margin-bottom: 50px;
}

button.btn.btn-primary.blog-btn-mobile {
  display: none;
}

/* ------------> Mobile Knowlege */
@media screen and (max-width: 768px) {
  button.btn.btn-primary.blog-btn-mobile {
    display: block;
    margin-top: 18px;
  }

  button.btn.btn-primary.blog-btn {
    display: none;
  }

  .blog-banner {
    padding: 45px 15px;
  }

  .blog-wrapper .row {
    --bs-gutter-x: 1.5rem;
  }

  h2.h2-blog-h2 {
    font-size: 25px;
  }

  .container-large {
    display: block;
    grid-gap: 31px;
    gap: 31px;
  }

  .small-para-blog {
    margin: 15px 0 30px;
    font-size: 18px;
  }

  .col-md-6.livingADHD {
    padding-top: 0;
    padding-left: 0;
    padding-bottom: 22px;
  }

  .tab-menu-blog {
    display: grid;
    grid-gap: 20px;
    gap: 20px;
  }

  h1.knowledge-heading {
    font-size: 30px;
    font-weight: 600;
  }

  .tab-menu-blog {
    padding-bottom: 25px;
  }

  .col-md-6.livingADHD {
    padding-top: 0;
    padding-left: 0;
  }

  button.btn.btn-primary.blog-btn {
    background-color: #368fff;
    padding: 12px 34px 12px 34px;
    font-size: 20px;
    border-radius: 7px;
    width: 100%;
  }

  .featured {
    font-size: 10px !important;
  }

  img.featured-blog-image.rounded-corners {
    margin-top: 25px;
  }

  img.insurance-file {
    width: 100% !important;
  }

  h1.sub-heading {
    font-size: 30px;
    font-weight: 700;
    padding-right: 5px;
    padding-left: 0;
    width: 100% !important;
  }

  span.sub-heading2 {
    font-size: 30px;
    font-weight: 700;
    color: #484c51;
    padding-bottom: 15px;
  }

  form.row.sub {
    padding-left: 15px;
    padding-bottom: 35px;
  }

  element.style {
    display: grid;
    gap: 20px;
  }

  .blog-side h1 {
    font-size: 20px;
  }

  .Blog-small {
    border: 0.5px solid #cbcbcb;
  }

  button.btn.btn-primary.mb-3.sub {
    width: 100%;
  }

  input#email-1 {
    margin-bottom: 24px;
  }

  input#inputName {
    margin-bottom: 24px;
  }
}

.Blog-small p:last-child {
  font-size: 30px;
  font-weight: bold;
}



.blogs-box {
  margin-top: 20px;
}

.blog-interested {
  margin-top: 90px;
}

.blog-one {
  margin-bottom: 30px;
}

.Blog-small {
  margin-top: 120px !important;
}

.Blog-small {
  text-align: center;
}

.Blog-small,
.blog-side {
  background-color: #fff;
  padding: 25px;
  margin-bottom: 25px;
  /* border-radius: 15px; */
}


.blogging .col-4 .blog-top h1 {
  font-size: 32px;
  text-align: center;
  font-weight: 300;
}

.blog-providers {
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: flex-start;
}

.blog-side p.blog-author-slug {
  margin: 0px;
}

img.author_img {
  width: 70px !important;
  height: 70px !important;
  border-radius: 300px;
}

.blogging .col-4 .blog-side h1 {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
}

.blog-providers {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 1rem;
  margin-top: 13px;
}

/* <----------------Mobile Knowlege */
/* <-------------Knowledge */

img.Mobile-img0menu {
  width: 19px;
}

.main-update-section {
  display: flex;
  justify-content: space-between;
}

span.show-payment {
  font-weight: 600;
}

span.edit-payment {
  text-decoration: underline;
  cursor: pointer;
}

span.show-payment small {
  color: #8d949a !important;
  font-size: 11px !important;
  font-weight: 400 !important;
}

img.wl_img {
  /* fill: red !important; */
  position: relative;
  width: 49px;
  top: -6.5px;
}

.uploaded-image {
  margin: 33px 0;
}

img.insurance-file {
  width: 30%;
}

.container.contact_container {
  margin-top: 4%;
}

.react-reveal.mt-4.mb-1 {
  margin-top: 0 !important;
}

.type_msg {
  border: 1px solid #00000066;
  padding: 6px;
}

span.no-thank {
  text-align: center !important;
  /* margin: auto; */
  margin-top: 4px;
  text-decoration: underline;
  cursor: pointer;
  color: grey;
  font-size: 13px;
}

span.no-thank:hover {
  color: var(--theme_color);
}

p.card-text.work-text.text-light-blue {
  text-align: center;
}

.work-card-description {
  text-align: center;
}

body .receiving-mess-container.by-you img {
  height: 210px !important;
  width: 454px;
}

.container.programFinder.pt-5.cancellation {
  margin-top: 85px;
}

.programFinder_step_7.screen_three.cancellation-bottom {
  margin-top: 33px;
}

h5.req_reason {
  display: flex;
  justify-content: center;
  margin-bottom: 22px;
  margin-top: 50px;
}

.Confirm_cancellation {
  padding-top: 65px;
  text-align: center;
  padding-bottom: 180px;
}

input.reason_input {
  border-radius: 6px;
  height: 43px;
}

img.docimg {
  padding: 10px;
  width: 250px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  float: right;
  margin-top: -58px;
  margin-right: 30px;
}

.programFinder_btns {
  clear: both;
}

.insurance_form {
  margin-top: 28px;
}

.horiz {
  clear: both;
}
#style2 small.pb-2.cart-price-item, #style2 small.pb-2.upsells-price-norm.upsells-price.d-block {
  top: 2px;
  position: relative;
}

#style2 .col-md-5.col-8:nth-child(2) small.pb-2.upsells-title.d-block {
  width: 112px;
}

.disease-text-2 ul.Important_safety_main_ul {    box-shadow: 0 2px 5px 0 rgb(0 0 0 / 16%), 0 2px 10px 0 rgb(0 0 0 / 12%);
  width: 470px;
  right: 0;
  left: 0;
  position: absolute;
  margin: 9px auto !important;
}
small.disease-text-2 {
  position: relative;
}
.disease-text-2 .Important_safety_main_ul li:first-child {
  margin-bottom: 10px;
}
.work-card-description ul.Important_safety_main_ul {
  position: absolute;
  z-index: 9;
  width: 455px;
}
.disease-text-2 ul.Important_safety_main_ul li,
.work-card-description ul.Important_safety_main_ul li {
  text-align: left;
}
.work-card-description small.Important_safety_main {
  position: absolute;
  left: 0;
  right: 0;
  top: 19px;
}
.work-card-description {
  position: relative;
}
h3.treatments_headName {
  text-shadow: -1px -1px 0 #f7f2f2, 1px -1px 0 #fff, -1px 1px 0 #fdf3f3, 1px 1px 0 #f1eeee;
}

@media(max-width:991px)
{
  .for-desktop {
    display: none;
}
.cart-list-order {
  font-size: 14px !important;
}
body .for-sex .product-image {
  height: 195px !important;
}
.for-sex 
 .card-points-weightloss-saxenda {
    margin-bottom: 0px;
}
}

@media(min-width:992px)
{
  .for-mobile{display: none;}
  .cart-list-order {
    font-size: 15px !important;
  }
}
.gapping-div.bmi h4 {
  text-align: center;
}
.gapping-div.bmi {
  padding-top: 151px !important;
  margin-top: 0px !important;
}
.gapping-div.howmuch {
  padding-top: 151px !important;
  margin-top: 0px !important;
  
}

.main-box-div input {background-color: transparent;
  font-size: 25px;
  width: 100%;
  border-bottom: 1px solid #67acfe !important;
  border: 0px;
}
.main-box-div.howmuch {
  width: 100%;
  margin: 0px auto 5px;
  padding: 19px;
  -webkit-box-shadow: #368fff24 0px 2px 16px;
  box-shadow: #368fff24 0px 2px 16px;
  text-align: center;
  border-radius: 20px;
  background-color: white;
}
.main-box-div.bmi {
  width: 30%;
  box-shadow: #368fff24 0px 2px 16px;
  margin: 0 auto 50px;
  padding: 15px;
  /* -webkit-box-shadow: 0 2px 16px rgba(54,143,255,.1411764705882353);
  box-shadow: 0 2px 16px rgba(54,143,255,.1411764705882353); */
}
.main-box-div label {
  font-size: 13px;
}
.main-box-div button.btn.btn-primary.mt-4 {
  margin: 20px auto 0px !important;
  display: table;
}
.bmi-cal {
  display: flex;
}
.one {
  display: -moz-box;
  display: flex;
  -moz-box-pack: justify;
  justify-content: center !important;
  grid-gap: 126px;
  gap: 15px;
}
.bmi-cal button {
  width: 120px;
}
.bmi-cal  {
  margin-top: 30px;
}
.bmiResult {
  font-size: 18px;
  text-align: center;
  margin-top: 20px !important;
}
.ant-slider.ant-slider-horizontal {
  width: 100%;
  height: 8px;
}
.howmuch-cal {
  flex-direction: column;
  justify-content: center;
  display: flex;
  align-items: center;
}
.howmuch-cal input.ant-input-number-input, .howmuch-cal .ant-input-number {
  border: 0px !important;
  text-align: center;
  font-size: 40px;
  height: unset;
}

.ant-input-number, .ant-input-number .ant-input-number-input-wrap, .ant-input-number .ant-input-number-input-wrap input {
  border: 0px !important;
  text-align: center;
  background-color: transparent;
  font-weight: bold;
  padding: 0px;
  bottom: 21px;
}
.semi {
  text-align: center;
}
.semis {
  text-align: center;
}
.ant-input-number-handler-wrap {
  display: none !important;
}
.howmuch-loose h5 {
  text-align: center;
 
}
.howmuch-loose {    padding-bottom: 0px;
  padding: 15px;
  padding-left: 0px;
  padding-right: 0px;
  
}
.howmuch-loose {
  padding-bottom: 0px;
}
.semi {
  background-color: #6eaaf5;
  padding: 8px;
  width: 100%;
  border-radius: 20px;
  height: 170px;
  /* border: 1px solid black; */
}
.semis {
  background-color: #368fff;
  padding: 12px;
  width: 100%;
  border-radius: 20px;
  height: 170px;
  /* border: 1px solid black; */
  margin-top: 15px;
}

.semi input {
  font-size: 65px !important;
  height: auto !important;
  line-height: 0px;
}
.semis input {
  font-size: 65px !important;
  height: auto !important;
  line-height: 0px;
}
:where().ant-slider .ant-slider-handle::after {
  width: 20px;
  height: 20px;
}
.howmuch .box {
  display: flex;
  align-items: center;
  gap: 2px;
  justify-content: center;
  flex-direction: column;
}
.ant-slider-rail {
  top: 4px;
}
:where().ant-slider-horizontal .ant-slider-track {
  height: 8px;
  top: 8px;
}
:where().ant-slider .ant-slider-handle:hover::after, :where().ant-slider .ant-slider-handle:active::after, :where().ant-slider .ant-slider-handle:focus::after {
  width: 20px;
  height: 20px;

}
.main-box-div.howmuch {
  height: 120px;
}
.ant-slider.ant-slider-horizontal {
  margin-top: -20px;
}
.howmuch-loose h6{
  color: white;
}
select.mt-1.bmii {background-color: transparent;
  font-size: 25px;
  width: 100%;
  border-bottom: 1px solid #67acfe!important;
  border: 0;
}

input[type=number]::-webkit-inner-spin-button {
  opacity: 1
}

.howback {
  background: #f0f8ff;
  padding: 15px;
  width: 40%;
  margin: 0px auto 29px;
}
.text {
  position: relative;
  bottom: 19px;
  font-size: 13px;
}
.bmi_weight::-webkit-outer-spin-button,
.bmi_weight::-webkit-inner-spin-button {
  -webkit-appearance:auto;
  margin: 0;
}
label.me-1.mt-4.erValue {
  color: red;
  position: relative;
  bottom: 20px;
}


.custom_checkbox{
    display: flex;
    align-items: center;
    gap: 4px;
    width: 250px;
    margin-top: 10px;
}

.custom_checkbox input {
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: #fff;
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: black;
  width: 1.15em;
  height: 1.15em;
  border: 0.15em solid black;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  display: grid;
  place-content: center;
}

.custom_checkbox input::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em black;
}

.custom_checkbox input:checked::before {
  transform: scale(1);
}